import {Injectable} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ExpirationsValidator} from '../validators/expirations.validator';
import {LocalStorageService} from "./local-storage.service";
import {HelpersService} from "./helpers.service";

@Injectable({
  providedIn: 'root'
})
export class PaymentModuleService {

  form: FormGroup;

  namePattern = /^[a-z ,.'-]+$/i;
  emailPattern = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  lettersPattern = /^[a-zA-Z]+$/;
  digitsPattern = /^\d+$/;

  selectedInstallmentId: string;

  constructor(private fb: FormBuilder,
              public ls: LocalStorageService,
              private helpers: HelpersService) {
  }

  buildForm(defaultMethod = 'none', defaultValues: any = {}) {
    this.form = this.fb.group({
      method: [defaultMethod, Validators.required],
      cardNumber: [defaultValues.cardNumber ? defaultValues.cardNumber : ''],
      expiration_month: [defaultValues.expiration ? defaultValues.expiration.slice(0, 2) : ''],
      expiration_year: [defaultValues.expiration ? defaultValues.expiration.slice(2, defaultValues.expiration.length) : ''],
      seriesCode: [defaultValues.seriesCode ? defaultValues.seriesCode : ''],
      approvalCode: [defaultValues.approvalCode ? defaultValues.approvalCode : ''],
      cardHolderTitle: [defaultValues.cardHolderTitle ? defaultValues.cardHolderTitle : 'MR'],
      cardHolderName: [defaultValues.cardHolderName ? defaultValues.cardHolderName : ''],
      cardHolderSurname: [defaultValues.cardHolderSurname ? defaultValues.cardHolderSurname : ''],
      cardHolderEmail: [defaultValues.cardHolderEmail ? defaultValues.cardHolderEmail : ''],
      phone: this.fb.group({
        areaCode: [defaultValues.phone && defaultValues.phone.areaCode ? defaultValues.phone.areaCode : ''],
        countryCode: [defaultValues.phone && defaultValues.phone.countryCode ? defaultValues.phone.countryCode : ''],
        number: [defaultValues.phone && defaultValues.phone.number ? defaultValues.phone.number : ''],
      }),
      countryCode: [defaultValues.countryCode ? defaultValues.countryCode : ''],
      stateProv: [defaultValues.stateProv ? defaultValues.stateProv : ''],
      cityName: [defaultValues.cityName ? defaultValues.cityName : ''],
      postalCode: [defaultValues.postalCode ? defaultValues.postalCode : ''],
      street1: [defaultValues.street1 ? defaultValues.street1 : ''],
      cardCode: [defaultValues.cardCode ? defaultValues.cardCode : 'VI'],
      cardType: [defaultValues.cardType ? defaultValues.cardType : 'CC'],
      surchargeType: [defaultValues?.surchargeType ? defaultValues?.surchargeType : ''],
    });

    let payment = this.form;

    // payment cardCode changes
    payment.get('cardCode').valueChanges.subscribe(cardCode => {
      const cardNumberControl = payment.get('cardNumber');
      cardNumberControl.setValidators([
        Validators.required,
        ExpirationsValidator.CardNumberLuhnValidator(payment.get('cardCode')),
        Validators.pattern(this.digitsPattern),
      ]);
      cardNumberControl.updateValueAndValidity();
    });

    // payment method changes
    payment.get('method').valueChanges.subscribe(
      (method: string) => {
        let elements = [
          'cardNumber',
          'expiration_month',
          'expiration_year',
          'seriesCode',
          'approvalCode',
          'cardHolderName',
          'cardHolderSurname',
          'cardHolderEmail',
          'phone.number',
          'cityName',
        ];

        let validators = {
          'cardNumber': [Validators.required, ExpirationsValidator.CardNumberLuhnValidator(payment.get('cardCode')), Validators.pattern(this.digitsPattern)],
          'expiration_month': [Validators.required, ExpirationsValidator.MonthValidator()],
          'expiration_year': [Validators.required, ExpirationsValidator.YearValidator()],
          'seriesCode': [Validators.pattern(this.digitsPattern)],
          'approvalCode': [Validators.pattern(this.digitsPattern)],
          'cardHolderName': [Validators.pattern(this.namePattern)],
          'cardHolderSurname': [Validators.pattern(this.namePattern)],
          'cardHolderEmail': [Validators.pattern(this.emailPattern)],
          'phone.number': [Validators.pattern(this.digitsPattern)],
          'cityName': [Validators.pattern(this.namePattern)],
          'cardCode': [Validators.required],
          'cardType': [Validators.required],
        };

        switch (method) {
          case 'none':
          case 'agencyCard':
          case 'agencyCash':
            for (let i in elements) {
              payment.get(elements[i]).clearValidators();
            }
            break;
          case 'card':
            for (let i in elements) {
              payment.get(elements[i]).setValidators(validators[elements[i]]);

            }
            break;
        }

        for (let i in elements) {
          payment.get(elements[i]).updateValueAndValidity();
        }
      });

    if (defaultMethod !== 'none') {
      // trigger validation rules apply
      payment.patchValue({method: 'none'})
      payment.patchValue({method: defaultMethod})
    }
  }

  getPaymentData(resetPayment = false) {
    let payment = {...this.form.value};

    if (resetPayment) {
      payment.method = 'none';
    } else if (['easypay', 'uatp'].includes(payment.method)) {
      payment.method = 'card';
    }

    if (payment.method !== 'none') {
      payment.expiration = payment.expiration_month.toString() + payment.expiration_year.toString();
      delete payment.expiration_month;
      delete payment.expiration_year;

      if (payment.method !== 'card') {
        delete payment.cardType;
        delete payment.cardCode;
        delete payment.cardNumber;
        delete payment.seriesCode;
        delete payment.approvalCode;
        delete payment.street1;
        delete payment.cityName;
        delete payment.stateProv;
        delete payment.postalCode;
        delete payment.countryCode;
        delete payment.expiration;
      }
      if (this.selectedInstallmentId) {
        payment.installmentId = this.selectedInstallmentId;
      }
    } else {
      payment = null;
    }
    return payment;
  }

  get isNonePaymentMethod() {
    return this?.form?.get('method')?.value === 'none';
  }

  fillPaymentData(provider: string, payment: AbstractControl) {
    switch (provider) {
      case 'A3': {
        payment.patchValue({
          cardCode: 'CA',
          cardType: 'CC',
          cardNumber: '5577810000000004',
          seriesCode: '123',
          cardHolderName: 'JOHN',
          cardHolderSurname: 'DOE',
          cardHolderTitle: 'MR',
          cardHolderEmail: 'accept@accept.com',
          cashName: 'JOHN',
          cashSurname: 'DOE',
          cashTitle: 'MR',
          cashEmail: 'accept@accept.com',
          email: 'accept@accept.com',
          name: 'Manuel',
          surname: 'Martinez',
          title: 'MR',
          street1: 'Ibiza 21',
          cityName: 'MAD',
          stateProv: '28',
          postalCode: '28900',
          countryCode: 'ES',
          expiration: '0521',
          expiration_month: '01',
          expiration_year: '39',
          phone: {
            areaCode: '',
            countryCode: '34',
            number: '646666621',
          }
        });
        break;
      }
      case 'AA': {
        payment.patchValue({
          cardCode: 'CA',
          cardType: 'CC',
          cardNumber: '5577810000000004',
          seriesCode: '123',
          cardHolderName: 'JOHN',
          cardHolderSurname: 'DOE',
          cardHolderTitle: 'MR',
          cardHolderEmail: 'accept@accept.com',
          cashName: 'JOHN',
          cashSurname: 'DOE',
          cashTitle: 'MR',
          cashEmail: 'accept@accept.com',
          email: 'accept@accept.com',
          name: 'Manuel',
          surname: 'Martinez',
          title: 'MR',
          street1: 'Ibiza 21',
          cityName: 'MAD',
          stateProv: '28',
          postalCode: '28900',
          countryCode: 'ES',
          expiration: '0521',
          expiration_month: '01',
          expiration_year: '39',
          phone: {
            areaCode: '',
            countryCode: '34',
            number: '646666621',
          }
        });
        break;
      }
      case 'AC': {
        payment.patchValue({
          // payment: {
          // method: 'card',
          cardCode: 'VI',
          cardType: 'CC',
          cardNumber: '4444333322221111',
          seriesCode: '737',
          cardHolderName: 'JOHN',
          cardHolderSurname: 'DOE',
          cardHolderTitle: 'MR',
          cardHolderEmail: 'accept@accept.com',
          cashName: 'JOHN',
          cashSurname: 'DOE',
          cashTitle: 'MR',
          cashEmail: 'accept@accept.com',
          email: 'accept@accept.com',
          name: 'Manuel',
          surname: 'Martinez',
          title: 'MR',
          street1: 'TEST',
          cityName: 'Washington',
          stateProv: 'DC',
          postalCode: '20100',
          countryCode: 'US',
          expiration: '1020',
          expiration_month: '10',
          expiration_year: '39',
          phone: {
            areaCode: '',
            countryCode: '34',
            number: '646666621',
          }
        });
        break;
      }
      case 'AY': {
        payment.patchValue({
          cardCode: 'CA',
          cardType: 'CC',
          cardNumber: '5400000000000005',
          seriesCode: '123',
          cardHolderName: 'JOHN',
          cardHolderSurname: 'DOE',
          cardHolderTitle: 'MR',
          cardHolderEmail: 'accept@accept.com',
          cashName: 'JOHN',
          cashSurname: 'DOE',
          cashTitle: 'MR',
          cashEmail: 'accept@accept.com',
          email: 'accept@accept.com',
          name: 'Manuel',
          surname: 'Martinez',
          title: 'MR',
          street1: 'Ibiza 21',
          cityName: 'MAD',
          stateProv: '28',
          postalCode: '28900',
          countryCode: 'ES',
          expiration: '0521',
          expiration_month: '01',
          expiration_year: '39',
          phone: {
            areaCode: '',
            countryCode: '34',
            number: '646666621',
          }
        });
        break;
      }
      case 'BA': {
        payment.patchValue({
          cardCode: 'CA',
          cardType: 'CC',
          cardNumber: '5199992312641465',
          seriesCode: '123',
          cardHolderName: 'JOHN',
          cardHolderSurname: 'DOE',
          cardHolderTitle: 'MR',
          cardHolderEmail: 'accept@accept.com',
          cashName: 'JOHN',
          cashSurname: 'DOE',
          cashTitle: 'MR',
          cashEmail: 'accept@accept.com',
          email: 'accept@accept.com',
          name: 'Manuel',
          surname: 'Martinez',
          title: 'MR',
          street1: 'Ibiza 21',
          cityName: 'MAD',
          stateProv: '28',
          postalCode: '28900',
          countryCode: 'ES',
          expiration: '0521',
          expiration_month: '05',
          expiration_year: '32',
          phone: {
            areaCode: '',
            countryCode: '34',
            number: '646666621',
          }
        });
        break;
      }
      case 'EK': {
        payment.patchValue({
          cardCode: 'CA',
          cardType: 'CC',
          cardNumber: '5577810000000004',
          seriesCode: '123',
          cardHolderName: 'JOHN',
          cardHolderSurname: 'DOE',
          cardHolderTitle: 'MR',
          cardHolderEmail: 'accept@accept.com',
          cashName: 'JOHN',
          cashSurname: 'DOE',
          cashTitle: 'MR',
          cashEmail: 'accept@accept.com',
          email: 'accept@accept.com',
          name: 'Manuel',
          surname: 'Martinez',
          title: 'MR',
          street1: 'Ibiza 21',
          cityName: 'MAD',
          stateProv: '28',
          postalCode: '28900',
          countryCode: 'ES',
          expiration: '0521',
          expiration_month: '01',
          expiration_year: '39',
          phone: {
            areaCode: '',
            countryCode: '34',
            number: '646666621',
          }
        });
        break;
      }
      case 'FR': {
        payment.patchValue({
          cardCode: 'VI',
          cardType: 'CC',
          cardNumber: '4910020011111118',
          seriesCode: '747',
          cardHolderName: 'JOHN',
          cardHolderSurname: 'DOE',
          cardHolderTitle: 'MR',
          cardHolderEmail: 'accept@accept.com',
          cashName: 'JOHN',
          cashSurname: 'DOE',
          cashTitle: 'MR',
          cashEmail: 'accept@accept.com',
          email: 'accept@accept.com',
          name: 'Manuel',
          surname: 'Martinez',
          title: 'MR',
          street1: 'Ibiza 21',
          cityName: 'MAD',
          stateProv: '28',
          postalCode: '28900',
          countryCode: 'ES',
          expiration: '0521',
          expiration_month: '10',
          expiration_year: '28',
          phone: {
            areaCode: '',
            countryCode: '34',
            number: '646666621',
          }
        });
        break;
      }
      case 'HR': {
        payment.patchValue({
          // payment: {
          // method: 'card',
          cardCode: 'VI',
          cardType: 'CC',
          cardNumber: '4444333322221111',
          seriesCode: '123',
          cardHolderName: 'JOHN',
          cardHolderSurname: 'DOE',
          cardHolderTitle: 'MR',
          cardHolderEmail: 'accept@accept.com',
          cashName: 'JOHN',
          cashSurname: 'DOE',
          cashTitle: 'MR',
          cashEmail: 'accept@accept.com',
          email: 'accept@accept.com',
          name: 'Manuel',
          surname: 'Martinez',
          title: 'MR',
          street1: 'TEST',
          cityName: 'Washington',
          stateProv: 'DC',
          postalCode: '20100',
          countryCode: 'US',
          expiration: '1020',
          expiration_month: '10',
          expiration_year: '39',
          phone: {
            areaCode: '',
            countryCode: '34',
            number: '646666621',
          }
        });
        break;
      }
      case 'IB': {
        payment.patchValue({
          // method: 'card',
          cardCode: 'VI',
          cardType: 'CC',
          cardNumber: '4012999999999999',
          seriesCode: '407',
          cardHolderName: 'Dinel',
          cardHolderSurname: 'Garcia Garcia',
          cardHolderTitle: 'MR',
          cardHolderEmail: 'accept@accept.com',
          cashName: 'Manuel',
          cashSurname: 'Martinez',
          cashTitle: 'MR',
          cashEmail: 'accept@accept.com',
          email: 'accept@accept.com',
          name: 'Dinel',
          surname: 'Garcia Garcia',
          title: 'MR',
          street1: 'Leganitos 21',
          cityName: 'MAD',
          stateProv: '28',
          postalCode: '28900',
          countryCode: 'ES',
          expiration: '1219',
          expiration_month: '11',
          expiration_year: '39',
          phone: {
            areaCode: '',
            countryCode: '34',
            number: '646666621',
          }
        });
        break;
      }
      case 'LH': {
        payment.patchValue({
          cardCode: 'CA',
          cardType: 'CC',
          cardNumber: '5577810000000004',
          seriesCode: '123',
          cardHolderName: 'JOHN',
          cardHolderSurname: 'DOE',
          cardHolderTitle: 'MR',
          cardHolderEmail: 'accept@accept.com',
          cashName: 'JOHN',
          cashSurname: 'DOE',
          cashTitle: 'MR',
          cashEmail: 'accept@accept.com',
          email: 'accept@accept.com',
          name: 'Manuel',
          surname: 'Martinez',
          title: 'MR',
          street1: 'Ibiza 21',
          cityName: 'MAD',
          stateProv: '28',
          postalCode: '28900',
          countryCode: 'ES',
          expiration: '0521',
          expiration_month: '01',
          expiration_year: '39',
          phone: {
            areaCode: '',
            countryCode: '34',
            number: '646666621',
          }
        });
        break;
      }
      case 'QF': {
        payment.patchValue({
          cardCode: 'CA',
          cardType: 'CC',
          cardNumber: '5577810000000004',
          seriesCode: '123',
          cardHolderName: 'JOHN',
          cardHolderSurname: 'DOE',
          cardHolderTitle: 'MR',
          cardHolderEmail: 'accept@accept.com',
          cashName: 'JOHN',
          cashSurname: 'DOE',
          cashTitle: 'MR',
          cashEmail: 'accept@accept.com',
          email: 'accept@accept.com',
          name: 'Manuel',
          surname: 'Martinez',
          title: 'MR',
          street1: 'Ibiza 21',
          cityName: 'MAD',
          stateProv: '28',
          postalCode: '28900',
          countryCode: 'ES',
          expiration: '0521',
          expiration_month: '01',
          expiration_year: '39',
          phone: {
            areaCode: '',
            countryCode: '34',
            number: '646666621',
          }
        });
        break;
      }
      case 'QR': {
        payment.patchValue({
          cardCode: 'CA',
          cardType: 'CC',
          cardNumber: '5577810000000004',
          seriesCode: '123',
          cardHolderName: 'JOHN',
          cardHolderSurname: 'DOE',
          cardHolderTitle: 'MR',
          cardHolderEmail: 'accept@accept.com',
          cashName: 'JOHN',
          cashSurname: 'DOE',
          cashTitle: 'MR',
          cashEmail: 'accept@accept.com',
          email: 'accept@accept.com',
          name: 'Manuel',
          surname: 'Martinez',
          title: 'MR',
          street1: 'Ibiza 21',
          cityName: 'MAD',
          stateProv: '28',
          postalCode: '28900',
          countryCode: 'ES',
          expiration: '0521',
          expiration_month: '01',
          expiration_year: '39',
          phone: {
            areaCode: '',
            countryCode: '34',
            number: '646666621',
          }
        });
        break;
      }
      case 'SQ': {
        payment.patchValue({
          cardCode: 'VI',
          cardType: 'CC',
          cardNumber: '4012999999999999',
          seriesCode: '123',
          cardHolderName: 'Manuel',
          cardHolderSurname: 'Martinez',
          cardHolderTitle: 'MR',
          cardHolderEmail: 'accept@accept.com',
          cashName: 'Manuel',
          cashSurname: 'Martinez',
          cashTitle: 'MR',
          cashEmail: 'accept@accept.com',
          email: 'accept@accept.com',
          name: 'Daniel',
          surname: 'Garcia Garcia',
          title: 'MR',
          street1: 'Leganitos 21',
          cityName: 'BER',
          stateProv: 'Berlin',
          postalCode: '10115',
          countryCode: 'DE',
          expiration: '1020',
          expiration_month: '10',
          expiration_year: '32',
          phone: {
            areaCode: '',
            countryCode: '34',
            number: '646666621',
          }
        });
        break;
      }
      case 'TPS': {
        payment.patchValue({
          // method: 'card',
          cardCode: 'VI',
          cardType: 'CC',
          cardNumber: '4111111111111111',
          seriesCode: '123',
          cardHolderName: 'JOHN',
          cardHolderSurname: 'DOE',
          cardHolderTitle: 'MR',
          cardHolderEmail: 'accept@accept.com',
          cashName: 'JOHN',
          cashSurname: 'DOE',
          cashTitle: 'MR',
          cashEmail: 'accept@accept.com',
          email: 'accept@accept.com',
          name: 'Manuel',
          surname: 'Martinez',
          title: 'MR',
          street1: 'Ibiza 21',
          cityName: 'MAD',
          stateProv: '28',
          postalCode: '28900',
          countryCode: 'ES',
          expiration: '0521',
          expiration_month: '01',
          expiration_year: '39',
          phone: {
            areaCode: '',
            countryCode: '34',
            number: '646666621',
          }
        });
        break;
      }
      case 'UA': {
        payment.patchValue({
          cardCode: 'VI',
          cardType: 'CC',
          cardNumber: '4508750015741019',
          seriesCode: '000',
          cardHolderName: 'JOHN',
          cardHolderSurname: 'DOE',
          cardHolderTitle: 'MR',
          cardHolderEmail: 'accept@accept.com',
          cashName: 'JOHN',
          cashSurname: 'DOE',
          cashTitle: 'MR',
          cashEmail: 'accept@accept.com',
          email: 'accept@accept.com',
          name: 'Manuel',
          surname: 'Martinez',
          title: 'MR',
          street1: 'Goerzallee 156',
          cityName: 'Berlin',
          stateProv: 'BE',
          postalCode: '14167',
          countryCode: 'DE',
          expiration: '0521',
          expiration_month: '01',
          expiration_year: '39',
          phone: {
            areaCode: '',
            countryCode: '34',
            number: '646666621',
          }
        });
        break;
      }
      case 'VY': {
        payment.patchValue({
          cardCode: 'VI',
          cardType: 'CC',
          cardNumber: '4444333322221111',
          seriesCode: '737',
          cardHolderName: 'Manuel',
          cardHolderSurname: 'Martinez',
          cardHolderTitle: 'MR',
          cardHolderEmail: 'accept@accept.com',
          cashName: 'Manuel',
          cashSurname: 'Martinez',
          cashTitle: 'MR',
          cashEmail: 'accept@accept.com',
          email: 'accept@accept.com',
          name: 'Dinel',
          surname: 'Garcia Garcia',
          title: 'MR',
          street1: 'Leganitos 21',
          cityName: 'MAD',
          stateProv: '28',
          postalCode: '28900',
          countryCode: 'ES',
          expiration: '1219',
          expiration_month: '03',
          expiration_year: '30',
          phone: {
            areaCode: '',
            countryCode: '34',
            number: '646666621',
          }
        });
        break;
      }
      case 'WS': {
        payment.patchValue({
          cardCode: 'CA',
          cardType: 'CC',
          cardNumber: '5577810000000004',
          seriesCode: '123',
          cardHolderName: 'Manuel',
          cardHolderSurname: 'Martinez',
          cardHolderTitle: 'MR',
          cardHolderEmail: 'accept@accept.com',
          cashName: 'Manuel',
          cashSurname: 'Martinez',
          cashTitle: 'MR',
          cashEmail: 'accept@accept.com',
          email: 'accept@accept.com',
          name: 'Daniel',
          surname: 'Garcia Garcia',
          title: 'MR',
          street1: 'Leganitos 21',
          cityName: 'BER',
          stateProv: 'Berlin',
          postalCode: '10115',
          countryCode: 'DE',
          expiration: '1219',
          expiration_month: '01',
          expiration_year: '39',
          phone: {
            areaCode: '',
            countryCode: '34',
            number: '646666621',
          }
        });
        break;
      }
      case 'KL':
      case 'AF': {
        payment.patchValue({
          cardCode: 'VI',
          cardType: 'CC',
          cardNumber: '4000020000000000',
          seriesCode: '737',
          cardHolderName: 'JOHN',
          cardHolderSurname: 'DOE',
          cardHolderTitle: 'MR',
          cardHolderEmail: 'accept@accept.com',
          cashName: 'JOHN',
          cashSurname: 'DOE',
          cashTitle: 'MR',
          cashEmail: 'accept@accept.com',
          email: 'accept@accept.com',
          name: 'Manuel',
          surname: 'Martinez',
          title: 'MR',
          street1: 'Ibiza 21',
          cityName: 'MAD',
          stateProv: '28',
          postalCode: '28900',
          countryCode: 'ES',
          expiration: '0521',
          expiration_month: '03',
          expiration_year: '30',
          phone: {
            areaCode: '',
            countryCode: '34',
            number: '646666621',
          }
        });
        break;
      }
      case 'LA': {
        payment.patchValue({
          cardCode: 'VI',
          cardType: 'CC',
          cardNumber: '4000000000001000',
          seriesCode: '123',
          cardHolderName: 'JOHN',
          cardHolderSurname: 'DOE',
          cardHolderTitle: 'MR',
          cardHolderEmail: 'accept@accept.com',
          cashName: 'JOHN',
          cashSurname: 'DOE',
          cashTitle: 'MR',
          cashEmail: 'accept@accept.com',
          email: 'accept@accept.com',
          name: 'Manuel',
          surname: 'Martinez',
          title: 'MR',
          street1: 'Ibiza 21',
          cityName: 'MAD',
          stateProv: '28',
          postalCode: '28900',
          countryCode: 'ES',
          expiration: '0521',
          expiration_month: '12',
          expiration_year: '34',
          phone: {
            areaCode: '',
            countryCode: '34',
            number: '646666621',
          }
        });
        break;
      }
      case 'LO': {
        payment.patchValue({
          cardCode: 'CA',
          cardType: 'CC',
          cardNumber: '5555444433331111',
          seriesCode: '737',
          cardHolderName: 'JOHN',
          cardHolderSurname: 'DOE',
          cardHolderTitle: 'MR',
          cardHolderEmail: 'accept@accept.com',
          cashName: 'JOHN',
          cashSurname: 'DOE',
          cashTitle: 'MR',
          cashEmail: 'accept@accept.com',
          email: 'accept@accept.com',
          name: 'Manuel',
          surname: 'Martinez',
          title: 'MR',
          street1: 'Ibiza 21',
          cityName: 'MAD',
          stateProv: '28',
          postalCode: '28900',
          countryCode: 'ES',
          expiration: '0521',
          expiration_month: '03',
          expiration_year: '30',
          phone: {
            areaCode: '',
            countryCode: '34',
            number: '646666621',
          }
        });
        break;
      }
      case 'U2': {
        payment.patchValue({
          cardCode: 'VI',
          cardType: 'CC',
          cardNumber: '4444333322221111',
          seriesCode: '737',
          cardHolderName: 'JOHN',
          cardHolderSurname: 'DOE',
          cardHolderTitle: 'MR',
          cardHolderEmail: 'accept@accept.com',
          cashName: 'JOHN',
          cashSurname: 'DOE',
          cashTitle: 'MR',
          cashEmail: 'accept@accept.com',
          email: 'accept@accept.com',
          name: 'Manuel',
          surname: 'Martinez',
          title: 'MR',
          street1: 'Ibiza 21',
          cityName: 'LON',
          stateProv: '28',
          postalCode: '123',
          countryCode: 'GB',
          expiration: '0521',
          expiration_month: '03',
          expiration_year: '30',
          phone: {
            areaCode: '',
            countryCode: '44',
            number: '7400123456',
          }
        });
        break;
      }
      case 'UX': {
        payment.patchValue({
          cardCode: 'CA',
          cardType: 'CC',
          cardNumber: '5100081112223332',
          seriesCode: '737',
          cardHolderName: 'JOHN',
          cardHolderSurname: 'DOE',
          cardHolderTitle: 'MR',
          cardHolderEmail: 'accept@accept.com',
          cashName: 'JOHN',
          cashSurname: 'DOE',
          cashTitle: 'MR',
          cashEmail: 'accept@accept.com',
          email: 'accept@accept.com',
          name: 'Manuel',
          surname: 'Martinez',
          title: 'MR',
          street1: 'Ibiza 21',
          cityName: 'MAD',
          stateProv: '28',
          postalCode: '28900',
          countryCode: 'ES',
          expiration: '0521',
          expiration_month: '03',
          expiration_year: '30',
          phone: {
            areaCode: '',
            countryCode: '34',
            number: '646666621',
          }
        });
        break;
      }

      default: {
        payment.patchValue({
          cardCode: 'VI',
          cardType: 'CC',
          cardNumber: '4005550000000001',
          seriesCode: '123',
          cardHolderName: 'JOHN',
          cardHolderSurname: 'DOE',
          cardHolderTitle: 'MR',
          cardHolderEmail: 'accept@accept.com',
          cashName: 'JOHN',
          cashSurname: 'DOE',
          cashTitle: 'MR',
          cashEmail: 'accept@accept.com',
          email: 'accept@accept.com',
          name: 'Manuel',
          surname: 'Martinez',
          title: 'MR',
          street1: 'Ibiza 21',
          cityName: 'MAD',
          stateProv: '28',
          postalCode: '28900',
          countryCode: 'ES',
          expiration: '0521',
          expiration_month: '01',
          expiration_year: '39',
          phone: {
            areaCode: '',
            countryCode: '34',
            number: '646666621',
          }
        });
        break;
      }
    }

  }

}
