<app-offer-search
  [newOffersCount]="newOffersCount"
  [isShowOffersForUpdateBtn]="tempOffers"
  (emitIsSearching)="wasNewSearch($event)"
  (emitBuildStaticSidebar)="onBuildStaticSidebar($event)"
  (emitResetSidebarFilter)="onResetSidebarFilter($event)"
  (emitOriginDestinationsOnSearch)="onSearch($event)"
  (emitRefreshOffers)="onRefreshOffers()"
  (emitResetFixedOriginDestinations)="resetFixedOriginDestinationIfNeed()"
></app-offer-search>

 <div class="container" *ngIf="(searchObj | json) != '{}'">
  <div class="row">
    <div class="col-lg-12">
      <div *ngIf="!searchObj.totalOffers && searchObj.allowNewSearch">
        No offers
        {{ searchObj.loggedRequestTime ? '(' + searchObj.loggedRequestTime + 'sec)' : '' }}
      </div>
      <div *ngIf="searchObj.errorMessage" class="row">
        <div class="col alert-danger ml-3 mt-1 mr-3 p-3">
          {{ searchObj.errorMessage }}
        </div>
      </div>

      <app-offer-grid
        [offers]="sidebarFilteredOffers"
        [searchCabin]="searchObj.form.cabin"
        [isNewSearch]="isNewSearch"
        [isDoneSearch]="isShowMainLoader"
        [isOfferFound]="!!offers.length"
        (emitFiltersTouched)="markFiltersTouched()"
        (emitGridChange)="handleGridChange($event)"
      >
        <ng-container [ngTemplateOutlet]="dropdowns" *ngIf="offers.length"></ng-container>
      </app-offer-grid>

      <div class="custom-tab-wp" *ngIf="searchObj.form.type !== 'OW' && offers.length">
        <div class="switcher btn-group" role="group" aria-label="Button switcher">
          <button
            type="button"
            class="btn py-2"
            [ngClass]="activeOffersTab === OFFER_TAB.PER_LEG ? 'active' : 'default'"
            (click)="setOfferTab(OFFER_TAB.PER_LEG)"
          >
            Per leg
          </button>
          <button
            type="button"
            class="btn py-2"
            [ngClass]="activeOffersTab === OFFER_TAB.FULL_TRIP ? 'active' : 'default'"
            (click)="setOfferTab(OFFER_TAB.FULL_TRIP)"
          >
            Full trip
          </button>
        </div>
      </div>

      <ng-container *ngIf="activeOffersTab === OFFER_TAB.FULL_TRIP && offers.length && paginatedOffers.length">
        <div class="offer-info-header my-3">
          <h4 class="d-flex align-items-center gap-4 mb-0" *ngIf="offersCount">
            Itineraries:
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.34383 2.27187C5.70633 1.90938 6.29383 1.90938 6.65633 2.27187L10.7282 6.34438C11.0907 6.70688 11.0907 7.29375 10.7282 7.65562L6.65633 11.7281C6.29383 12.0906 5.70696 12.0906 5.34509 11.7281L1.27196 7.65625C1.18575 7.57022 1.11735 7.46802 1.07069 7.35552C1.02402 7.24302 1 7.12242 1 7.00063C1 6.87883 1.02402 6.75823 1.07069 6.64573C1.11735 6.53323 1.18575 6.43104 1.27196 6.345L5.34383 2.27187Z" fill="#9747ff"/>
            </svg>
            {{offersCount}}
            <span>/</span>
            Offers:
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
              <path d="M5 10.5C4.30833 10.5 3.65833 10.3687 3.05 10.106C2.44167 9.84333 1.9125 9.48717 1.4625 9.0375C1.0125 8.5875 0.656333 8.05833 0.394 7.45C0.131667 6.84167 0.000333333 6.19167 0 5.5C0 4.80833 0.131333 4.15833 0.394 3.55C0.656667 2.94167 1.01283 2.4125 1.4625 1.9625C1.9125 1.5125 2.44167 1.15633 3.05 0.894C3.65833 0.631667 4.30833 0.500333 5 0.5C5.69167 0.5 6.34167 0.631333 6.95 0.894C7.55833 1.15667 8.0875 1.51283 8.5375 1.9625C8.9875 2.4125 9.34383 2.94167 9.6065 3.55C9.86917 4.15833 10.0003 4.80833 10 5.5C10 6.19167 9.86867 6.84167 9.606 7.45C9.34333 8.05833 8.98717 8.5875 8.5375 9.0375C8.0875 9.4875 7.55833 9.84383 6.95 10.1065C6.34167 10.3692 5.69167 10.5003 5 10.5Z" fill="#4aaea8"/>
            </svg>
            {{gridModel.offers?.length}}
          </h4>
          <div class="form-inline sort-by-wp">
            <div class="mr-2">Sort By:</div>
            <app-custom-select
              class="sort-strategy-select"
              [(ngModel)]="sortStrategy"
              [options]="sortStrategyOptions | keys"
              [mapObject]="sortStrategyOptions"
              (emitValueChange)="changeSortStrategy($event)">
            </app-custom-select>
          </div>
        </div>

        <app-offer-info
          *ngFor="let offer of paginatedOffers; let i = index; trackBy: trackOfferInfo;"
          [offerCandidate]="offer"
          [urlData]="{travelers: searchObj.travelersForOfferURL, od: searchObj.originDestinationsForOfferURL}"
          [searchTypeOption]="searchTypeOptions[searchObj.form.type]"
          [checkObject]="checkObject"
          (emitOpenGroupOptions)="markFiltersTouched()"
          (emitSelectedLeg)="onSelectedFixedLeg($event)"
        ></app-offer-info>
      </ng-container>

      <ng-container *ngIf="activeOffersTab === OFFER_TAB.PER_LEG && offers.length">
        <app-offer-builder
          [urlData]="{travelers: searchObj.travelersForOfferURL, od: searchObj.originDestinationsForOfferURL}"
          [originDestinations]="searchObj.originDestinations"
          [searchTypeOption]="searchTypeOptions[searchObj.form.type]"
          [allOffers]="selectedOffers">
        </app-offer-builder>
      </ng-container>

    </div>
  </div>
</div>
<div class="loader-wrapper" *ngIf="searchObj && !offers.length && isShowMainLoader">
  <div class="loader-container">
    <app-loader></app-loader>
  </div>
</div>

<div class="button-to-top-wp" *ngIf="pageSize > 16 && activeOffersTab === OFFER_TAB.FULL_TRIP">
  <button (click)="windowToTop($event)"><i class="material-icons">keyboard_arrow_up</i></button>
</div>

<ng-template #dropdowns>
  <div class="custom-dropdown all-filters-dropdown" ngbDropdown>
    <div class="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
      <span class="material-symbols-outlined">
        filter_alt
      </span>
      All filters <ng-container *ngIf="countSelectedSidebarFilters">({{countSelectedSidebarFilters}})</ng-container>
    </div>
    <div class="all-filters-dropdown-menu" ngbDropdownMenu>
      <div class="card border-0 mb-0">
        <div class="card-body filters">
          <div class="filter-item" *ngIf="countSelectedSidebarFilters">
            <button class="reset-filters" (click)="resetAllFilters()">Reset all</button>
          </div>
          <div class="filter-item" [class.closed-item]="collapsedOptions.maxStops"
               [class.opened-item]="!collapsedOptions.maxStops">
            <button type="button" class="toggle-filter" (click)="onCollapseOptions('maxStops')">
              <div class="d-flex align-items-center gap-5">
                <h4>Stops</h4>
                <span *ngIf="sidebarIsShowResetButton.maxStops"
                      class="dark-red-color"
                      (click)="$event.stopPropagation(); resetStops()">
                  reset
                </span>
              </div>
              <i class="material-icons" [@rotate]="collapsedOptions.maxStops">
                keyboard_arrow_up
              </i>
            </button>
            <div [@collapse]="collapsedOptions.maxStops">
              <div class="form-group collapse-item" id="maxStops">
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="maxStops" id="maxStops1" [value]="0"
                         (change)="onMaxStopsChange()"
                         [(ngModel)]="sidebarFilter.maxStops">
                  <label class="form-check-label" for="maxStops1">
                    Non-stop
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="maxStops" id="maxStops2" [value]="1"
                         (change)="onMaxStopsChange()"
                         [(ngModel)]="sidebarFilter.maxStops">
                  <label class="form-check-label" for="maxStops2">
                    1 Stop
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="maxStops" id="maxStops3" [value]="10"
                         (change)="onMaxStopsChange()"
                         [(ngModel)]="sidebarFilter.maxStops">
                  <label class="form-check-label" for="maxStops3">
                    2 Stops or More
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="filter-item connections-item" [class.closed-item]="collapsedOptions.connections"
               [class.opened-item]="!collapsedOptions.connections">
            <button type="button" class="toggle-filter" (click)="onCollapseOptions('connections')">
              <div class="d-flex align-items-center gap-5">
                <h4>Connections</h4>
                <span *ngIf="sidebarIsShowResetButton.connections"
                      class="dark-red-color"
                      (click)="$event.stopPropagation(); resetConnections()">
                  reset
                </span>
              </div>
              <i class="material-icons" [@rotate]=collapsedOptions.connections>
                keyboard_arrow_up
              </i>
            </button>
            <div [@collapse]="collapsedOptions.connections">
              <div class="connection-group-wp collapse-item" id="Connections">
                <ng-container *ngFor="let route of connectionsSimpleModel | keys; first as first">
                  <app-connection-list-view
                    [connectionsSimpleModel]="connectionsSimpleModel"
                    [connectionPrices]="connectionPrices"
                    [route]="route"
                    [isFirstRoute]="first"
                    (emitConnectionsValueChanged)="onConnectionsValueChanged($event)"
                    (emitSelectedOneConnection)="selectedOnlyOneConnection($event)"
                    (emitDirectFlightsSelected)="directFlightsSelected($event)"
                    (emitDirectFlightsOnly)="directFlightsOnly($event)"
                    (emitSelectAllCheckboxes)="selectAllCheckboxes($event)"
                  >
                  </app-connection-list-view>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="filter-item"
               *ngIf="airportsInfo"
               [class.closed-item]="collapsedOptions.airports" [class.opened-item]="!collapsedOptions.airports">
            <button type="button" class="toggle-filter" (click)="onCollapseOptions('airports')">
              <div class="d-flex align-items-center gap-5">
                <h4>Airports</h4>
                <span *ngIf="sidebarIsShowResetButton.airports"
                      class="dark-red-color"
                      (click)="$event.stopPropagation(); resetAirports()">
                  reset
                </span>
              </div>
              <i class="material-icons" [@rotate]=collapsedOptions.airports>
                keyboard_arrow_up
              </i>
            </button>
            <div [@collapse]="collapsedOptions.airports">
              <div class="collapse-item" id="Airports">
                <ng-container *ngIf="(airportsInfo | keys).length">
                  <div class="airports-by-leg-wp" *ngFor="let route of airportsInfo | keys">
                    <h3 class="text-center cursor-default">
                      {{airportsInfo[route].depAirportCode}} → {{airportsInfo[route].arrAirportCode}}
                    </h3>
                    <div class="form-group">
                      <div class="air-way">
                        <span class="material-symbols-outlined">
                          flight_takeoff
                        </span>
                        Departure
                      </div>
                      <div *ngFor="let airport of airportsInfo[route].departure.airportCodes">
                        <ng-container *ngIf="airportsInfo[route].departure.prices[airport].min">
                          <div class="air-line">
                            <label class="checkbox custom-checkbox-1" for="dep_{{airport}}">
                              <input type="checkbox"
                                     id="dep_{{airport}}"
                                     [(ngModel)]="airportsInfo[route].departure.airportMap[airport]"
                                     (change)="onAirportsChange()"/>
                              <div class="checkbox__checkmark"></div>
                              <div class="checkbox__body">{{ airport }}</div>
                            </label>
                            <span class="air-price">
                              {{airportsInfo[route].departure.prices[airport].min | number :'1.2-2'}}
                              {{sidebarPrices.allPrices.currency | joinArrayPipe}}
                            </span>
                          </div>
                        </ng-container>
                      </div>
                    </div>

                    <div class="form-group">
                      <div class="air-way mt-3">
                        <span class="material-symbols-outlined">
                          flight_takeoff
                        </span>
                        Arrival
                      </div>
                      <div *ngFor="let airport of airportsInfo[route].arrival.airportCodes">
                        <ng-container *ngIf="airportsInfo[route].arrival.prices[airport].min">
                          <div class="air-line">
                            <label class="checkbox custom-checkbox-1" for="arr_{{airport}}">
                              <input type="checkbox"
                                     id="arr_{{airport}}"
                                     [(ngModel)]="airportsInfo[route].arrival.airportMap[airport]"
                                     (change)="onAirportsChange()"/>
                              <div class="checkbox__checkmark"></div>
                              <div class="checkbox__body">{{ airport }}</div>
                            </label>
                            <span class="air-price">
                              {{airportsInfo[route].arrival.prices[airport].min | number :'1.2-2'}}
                              {{sidebarPrices.allPrices.currency | joinArrayPipe}}
                            </span>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="filter-item" [class.closed-item]="collapsedOptions.flightNumbers"
               [class.opened-item]="!collapsedOptions.flightNumbers">
            <button type="button" class="toggle-filter" (click)="onCollapseOptions('flightNumbers')">
              <div class="d-flex align-items-center gap-5">
                <h4>Flight numbers</h4>
                <span *ngIf="sidebarIsShowResetButton.flightNumbers"
                      class="dark-red-color"
                      (click)="$event.stopPropagation(); resetSelectedFlightNumbers()">
                  reset
                </span>
              </div>
              <i class="material-icons" [@rotate]=collapsedOptions.flightNumbers>
                keyboard_arrow_up
              </i>
            </button>
            <div [@collapse]="collapsedOptions.flightNumbers">
              <div class="collapse-item" id="FlightNumbers">
                <div class="carrier-filter-wp">
                  <div *ngFor="let key of sidebarFilter.flightNumbers | keys; let index=index; first as first;"
                       class="form-group"
                       [class.mt-3]="!first">
                    <h3 class="text-center cursor-default">
                      {{ sidebarFilter.flightNumbers[key].route.split("-")[0] }} → {{ sidebarFilter.flightNumbers[key].route.split("-")[1] }}
                    </h3>
                    <app-multi-select
                      [data]="sidebarFilter.flightNumbers[key].flightNumbers"
                      [isShowFlightNumbers]="true"
                      [index]="index"
                      [value]="sidebarFilter.flightNumbersSelected[index]"
                      (valueChanged)="onSelectFlightNumber($event, index)"
                    ></app-multi-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="filter-item"
               *ngIf="sidebarFilter.paymentTimeLimitRanges"
               [class.closed-item]="collapsedOptions.paymentTimeLimit"
               [class.opened-item]="!collapsedOptions.paymentTimeLimit">
            <button type="button" class="toggle-filter" (click)="onCollapseOptions('paymentTimeLimit')">
              <div class="d-flex align-items-center gap-5">
                <h4>Issue time limit</h4>
                <span *ngIf="sidebarIsShowResetButton.paymentTimeLimit"
                      class="dark-red-color"
                      (click)="$event.stopPropagation(); resetPaymentTimeLimit()">
                  reset
                </span>
              </div>
              <i class="material-icons" [@rotate]=collapsedOptions.paymentTimeLimit>
                keyboard_arrow_up
              </i>
            </button>
            <div [@collapse]="collapsedOptions.paymentTimeLimit">
              <div id="payment-time-limit" class="collapse-item">
                <div class="form-group mb-2">
                  <div class="custom-slider">
                    <ngx-slider
                      appGraphRange
                      [modelDestination]="'paymentTimeLimit'"
                      [modelData]="sidebarFilter.paymentTimeLimitRanges"
                      [(value)]="sidebarFilter.paymentTimeLimitRanges.value"
                      [options]="sidebarFilter.paymentTimeLimitRanges | dateRangePipe"
                      (userChangeEnd)="onChangePaymentTimeLimitRange()"
                      (onSelectedBar)="onGraphRangeChange($event, null, 'paymentTimeLimit')"
                    ></ngx-slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="filter-item flight-duration" *ngIf="sidebarFilterData.durations.length"
               [class.closed-item]="collapsedOptions.duration"
               [class.opened-item]="!collapsedOptions.duration">
            <button type="button" class="toggle-filter" (click)="onCollapseOptions('duration')">
              <div class="d-flex align-items-center gap-5">
                <h4>Flight duration</h4>
                <span *ngIf="sidebarIsShowResetButton.flightDuration"
                      class="dark-red-color"
                      (click)="$event.stopPropagation(); resetFlightDuration()">
                  reset
                </span>
              </div>
              <i class="material-icons" [@rotate]=collapsedOptions.duration>
                keyboard_arrow_up
              </i>
            </button>
            <div [@collapse]="collapsedOptions.duration">
              <div id="FlightDuration" class="form-group mb-2 collapse-item">
                <ng-container *ngFor="let dur of sidebarFilterData.durations; let i = index">
                  <ng-container *ngIf="durationSliderOptions[i]">
                    <h3 class="text-center cursor-default">
                      {{ sidebarFilterData.durationNames[i].split("-")[0] }} → {{ sidebarFilterData.durationNames[i].split("-")[1] }}
                    </h3>
                    <div class="custom-slider">
                      <ngx-slider
                        appGraphRange
                        [modelDestination]="'duration'"
                        [modelData]="durationsExtended[i]"
                        [(value)]="sidebarFilter.durations[i]"
                        (userChangeEnd)="onChangeDuration(i);"
                        (onSelectedBar)="onGraphRangeChange($event, i, 'duration')"
                        [options]="durationSliderOptions[i]"
                      ></ngx-slider>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="filter-item" [class.closed-item]="collapsedOptions.price"
               [class.opened-item]="!collapsedOptions.price">
            <button type="button" class="toggle-filter" (click)="onCollapseOptions('price')">
              <div class="d-flex align-items-center gap-5">
                <h4>Price <span class="price-currency">({{sidebarPrices.allPrices.currency}})</span></h4>
                <span *ngIf="sidebarIsShowResetButton.priceRange"
                      class="dark-red-color"
                      (click)="$event.stopPropagation(); resetPriceRange()">
                  reset
                </span>
              </div>
              <i class="material-icons" [@rotate]=collapsedOptions.price>
                keyboard_arrow_up
              </i>
            </button>
            <div [@collapse]="collapsedOptions.price">
              <div id="Price" class="collapse-item">
                <div class="form-group mb-2">
                  <div class="custom-slider">
                    <ngx-slider
                      appGraphRange
                      [modelDestination]="'price'"
                      [modelData]="sidebarPrices.allPrices"
                      [(value)]="sidebarPrices.allPrices.minPriceValue"
                      [(highValue)]="sidebarPrices.allPrices.maxPriceValue"
                      [options]="sidebarPrices.allPrices.minPrice | ng5OptionPipe:sidebarPrices.allPrices.maxPrice"
                      (userChangeEnd)="onChangePriceRange()"
                      (onSelectedBar)="onGraphRangeChange($event, null, 'price')"
                    ></ngx-slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="filter-item"
               *ngIf="(sidebarFilter.airlines | json) != '{}'"
               [class.closed-item]="collapsedOptions.airlines"
               [class.opened-item]="!collapsedOptions.airlines">
            <button type="button" class="toggle-filter" (click)="onCollapseOptions('airlines')">
              <div class="d-flex align-items-center gap-5">
                <h4>Operators</h4>
                <span *ngIf="sidebarIsShowResetButton.airlines"
                      class="dark-red-color"
                      (click)="$event.stopPropagation(); resetAirlines()">
                  reset
                </span>
              </div>
              <i class="material-icons" [@rotate]=collapsedOptions.airlines>
                keyboard_arrow_up
              </i>
            </button>
            <div [@collapse]="collapsedOptions.airlines">
              <div id="Airlines" class="collapse-item">
                <div class="row">
                  <div class="col-md-6 mb-1 airline" *ngFor="let airline of sidebarFilter.airlines | keyvalue; last as last">
                    <label class="checkbox custom-checkbox-1" [for]="'airline' + airline.key"
                           [class.mb-0]="last">
                      <input type="checkbox" [id]="'airline' + airline.key" [checked]="airline.value" (change)="onAirlinesChange($event, airline.key)"/>
                      <div class="checkbox__checkmark"></div>
                      <div class="checkbox__body">{{ airline.key }}</div>
                    </label>
                    <span class="only-button" (click)="onAirlinesChange($event, airline.key, true)">only</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="custom-dropdown times-dropdown" ngbDropdown *ngIf="(sidebarFilter.timeRanges | keys).length">
    <button class="dropdown-button" ngbDropdownToggle>
      Times
      <span *ngIf="isShowResetTimeRangesButton" class="reset-btn">
        <span class="material-symbols-outlined" (click)="resetTimeRanges()">
          close
        </span>
      </span>
    </button>
    <div class="dropdown-menu times-dropdown-menu" ngbDropdownMenu>
      <ng-container *ngFor="let key of (sidebarFilter.timeRanges | keys); let i = index; last as last">
        <div class="form-group range-od" *ngIf="sidebarFilter.timeRanges[key].departure.minValue || sidebarFilter.timeRanges[key].arrival.minValue">
          <h3 class="text-center cursor-default">
            {{ sidebarFilter.timeRanges[key].depAirportCode }} → {{ sidebarFilter.timeRanges[key].arrAirportCode }}
          </h3>
          <div class="range-group mb-4">
            <div class="direction">
              <span class="material-symbols-outlined">
                flight_takeoff
              </span>
              Departure
            </div>
            <div class="custom-slider">
              <ngx-slider appGraphRange [modelDestination]="'timeRange'"
                [modelData]="sidebarFilter.timeRanges[key].departure"
                [(value)]="sidebarFilter.timeRanges[key].departure.minValue"
                [(highValue)]="sidebarFilter.timeRanges[key].departure.maxValue" (userChangeEnd)="onChangeTimeRange()"
                (onSelectedBar)="onGraphRangeChange($event, key, 'departure')"
                [options]="sidebarFilter.timeRanges[key] | timeRangePipe:'departure'"></ngx-slider>
            </div>
          </div>

          <div class="range-group" [class.mb-4]="!last">
            <div class="direction">
              <span class="material-symbols-outlined">
                flight_takeoff
              </span>
              Arrival
            </div>
            <div class="custom-slider">
              <ngx-slider appGraphRange [modelDestination]="'timeRange'"
                [modelData]="sidebarFilter.timeRanges[key].arrival"
                [(value)]="sidebarFilter.timeRanges[key].arrival.minValue"
                [(highValue)]="sidebarFilter.timeRanges[key].arrival.maxValue" (userChangeEnd)="onChangeTimeRange()"
                (onSelectedBar)="onGraphRangeChange($event, key, 'arrival')"
                [options]="sidebarFilter.timeRanges[key] | timeRangePipe:'arrival'"></ngx-slider>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
