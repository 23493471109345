<div class="modal-card card" ngbAutoFocus>
  <div class="card-header">
    <div class="row align-items-center">
      <div class="col">
        <h3 class="card-header-title" *ngIf="step === 1 || step === 5">Select Seats</h3>
        <h3 class="card-header-title" *ngIf="step === 2">Confirm selected seats</h3>
        <h3 class="card-header-title" *ngIf="step === 3 || step === 4">Add seats</h3>
      </div>
      <div class="col-auto">
        <i
          data-dismiss="modal"
          (click)="emitDismiss.emit(true)"
          class="material-icons float-right ml-3 close-icon"
        >close</i>
      </div>
    </div>
  </div>
  <div class="card-body modal-card-body-height" #cardBodyContainer>
    <ng-container *ngIf="step === 1">
      <div class="loader-wrapper" *ngIf="seatMapService.showSeatAvailabilityLoader">
        <app-loader></app-loader>
      </div>

      <div *ngIf="responseWarnings.length" class="row">
        <div class="col alert-warning m-3 p-3 rounded">
          <ul>
            <li *ngFor="let item of responseWarnings">{{item}}</li>
          </ul>
        </div>
      </div>

      <div *ngIf="!seatMapService.showSeatAvailabilityLoader">
        <error-alert-block
          [error]="seatMapService.seatAvailabilityError"
        ></error-alert-block>
      </div>

      <ng-container *ngIf="(seatMapService.seats | pairsImpure).length > 0">
        <app-seat-map
          [seats]="order.seats"
          [segments]="segments"
          [passengers]="order.passengers | excludePassengersPipe | sortPassengers"
          [seatAvailability]="seatAvailability"
          (seatSelect)="onSeatSelect($event)"
          (seatsBySegments)="getSeatsBySegments($event)">
        </app-seat-map>
      </ng-container>

    </ng-container>

    <ng-container *ngIf="step === 2 && (selectedSeatsCopy | pairsImpure).length">
      <error-alert-block
        [error]="orderChangeSeatError"
      ></error-alert-block>
      <div class="table-responsive">
        <table class="table table-default mb-0">
          <thead>
          <tr>
            <th>Passenger</th>
            <th>Flight Segment</th>
            <th>Seat</th>
            <th>Price</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let obj of (selectedSeatsCopy | pairsImpure)">
            <ng-container *ngIf="obj[1] | isInstanceOfObject">
              <tr *ngFor="let seatObj of (obj[1] | pairsImpure)">
                <td>{{order.passengers | getPassengerNameByRefPipe:seatObj[1]}}</td>
                <td class="whitespace-pre-line">{{order.flights | getSegmentRouteByIDPipe:obj[0]}}</td>
                <td>
                  {{seatsBySegment[obj[0]][seatObj[0]].location.row | padStart:2:'0' }}
                  {{seatsBySegment[obj[0]][seatObj[0]].location.column}}
                </td>
                <td>
                  {{seatsBySegment[obj[0]][seatObj[0]].price.consumer.total ?
                  seatsBySegment[obj[0]][seatObj[0]].price.consumer.total + ' ' +
                  seatsBySegment[obj[0]][seatObj[0]].price.consumer.currency : 'Free'}}
                </td>
              </tr>
            </ng-container>
          </ng-container>
          </tbody>
        </table>
      </div>
    </ng-container>

    <ng-container *ngIf="step === 3">
      <div class="payment-form-wp">
        <app-payment-form
          [validateTriggered]="validateTriggered"
          [paymentWarnings]="orderChangeSeatWarnings"
          [paymentError]="orderChangeSeatError"
          [allowedPaymentMethods]="order.allowedPaymentMethods"
          [cardSurchargesMap]="order.cardSurchargesMap"
          [totalPrice]='seatsTotalPrice'
          [logs]="order.logs">
        </app-payment-form>
      </div>
    </ng-container>

    <ng-container *ngIf="step === 4">
      <app-payment-info
        [order]="order"
        [type]="'pay_ancillaries_on_orderchange'"
        [paymentError]="orderChangeSeatError"
        (emitStepBack)="onStepBack()">
      </app-payment-info>
    </ng-container>

    <ng-container *ngIf="step === 5">
      <div class="mb-3" *ngIf="orderChangeSeatWarnings.length">
        <app-show-warnings [warnings]="orderChangeSeatWarnings"></app-show-warnings>
      </div>
      <div class="alert alert-success mb-0">
        Selected seats has been successfully ordered.
      </div>
    </ng-container>
  </div>

  <div class="card-footer text-right">
    <ng-container *ngIf="step === 1">
      <div class="select-seats-first-step-footer-wp">
        <div class="text-warn" *ngIf="(seatMapService.seats | pairsImpure).length"
             [ngClass]="{'text-red': hasNotFullSeatsForSegment }">Please, select seat for each passenger in segment.
        </div>
        <div class="button-wp">
          <button
            type="button"
            class="btn btn-secondary mr-3"
            (click)="emitDismiss.emit(true);">Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            [disabled]="!(!hasNotFullSeatsForSegment && hasFullSeatsForSegment)"
            (click)="showSelectedServices()">Next
          </button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="step === 2">
      <div class="btn mr-3" *ngIf="paymentLoader">
        <app-loader></app-loader>
      </div>
      <button
        type="button"
        class="btn btn-secondary mr-3"
        [disabled]="paymentLoader"
        (click)="setStep(1);">Back
      </button>
      <app-popover-wrapper
        popoverContent="Airline does not allow booking on hold."
        [disablePopover]="order.status === ORDER_STATUS.PENDING ||
                          order.status === ORDER_STATUS.TICKETED && order.allowedRequests?.ticketed?.AncillariesOnHold"
        [additionalClasses]="order.status !== ORDER_STATUS.PENDING ? 'd-inline-block mr-3' : 'd-inline-block'"
      >
        <button
          type="button"
          class="btn btn-tonal-primary"
          [disabled]="(order.status === ORDER_STATUS.TICKETED && !order.allowedRequests?.ticketed?.AncillariesOnHold) || paymentLoader"
          (click)="requestSeats(true)">Book On Hold
        </button>
      </app-popover-wrapper>
      <app-popover-wrapper
        *ngIf="order.status !== ORDER_STATUS.PENDING"
        popoverContent="Your User Role does not have the permission to proceed with this action."
        [disablePopover]="ls.role !== ROLES.BOOKER"
        additionalClasses="d-inline-block"
      >
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="ls.role === ROLES.BOOKER"
          (click)="setStep(3)"
        >
          Book & Pay
        </button>
      </app-popover-wrapper>
    </ng-container>

    <ng-container *ngIf="step === 3">
      <div class="btn mr-3" *ngIf="paymentLoader">
        <app-loader></app-loader>
      </div>
      <button
        type="button"
        class="btn btn-secondary mr-3"
        [disabled]="paymentLoader"
        (click)="setStep(2)">Back
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="paymentLoader"
        (click)="proceedStep3()">Proceed
      </button>
    </ng-container>

    <ng-container *ngIf="step === 4">
      <div class="btn mr-3" *ngIf="paymentLoader">
        <app-loader></app-loader>
      </div>
      <button
        type="button"
        class="btn btn-secondary mr-3"
        [disabled]="paymentLoader"
        (click)="setStep(3)">Back
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="paymentLoader || paymentService.isNonePaymentMethod"
        (click)="requestSeats()">Proceed
      </button>
    </ng-container>

    <ng-container *ngIf="step === 5">
      <button
        type="button"
        class="btn btn-primary mr-3"
        (click)="emitDismiss.emit(true);">OK
      </button>
    </ng-container>
  </div>
</div>
