<div class="modal-card card">
  <div class="card-header">
    <div class="row align-items-center">
      <div class="col">
        <h3 class="card-header-title" *ngIf="step === 1 || step === 5">Select Services</h3>
        <h3 class="card-header-title" *ngIf="step === 2">Confirm selected services</h3>
        <h3 class="card-header-title" *ngIf="step === 3 || step === 4">Add services</h3>
      </div>
      <div class="col-auto">
        <i
          data-dismiss="modal"
          (click)="emitDismiss.emit(true)"
          class="material-icons float-right ml-3 close-icon"
        >close</i>
      </div>
    </div>
  </div>
  <div class="card-body modal-card-body-height" #cardBodyContainer>

    <ng-container *ngIf="step === 1">
      <div class="row" *ngIf="showServiceListLoader">
        <div class="col mt-3">
          <div class="loader-wrapper">
            <app-loader></app-loader>
          </div>
        </div>
      </div>
      <div *ngIf="responseWarnings.length" class="row">
        <div class="col alert-warning m-3 p-3 rounded">
          <ul>
            <li *ngFor="let item of responseWarnings">{{item}}</li>
          </ul>
        </div>
      </div>
      <div *ngIf="serviceListError" class="row">
        <div class="col">
          <error-alert-block
            [error]="serviceListError"
          ></error-alert-block>
        </div>
      </div>
      <ngb-tabset #tabs="ngbTabset" *ngIf="!showServiceListLoader && !serviceListError.message">
        <ngb-tab
          *ngFor="let p of order.passengers | sortPassengers; let ii = index"
          id="{{ p.travelerReference }}"
          title="{{'(' + p.passengerType + ') ' + p.data.name + ' ' + p.data.surname }}">
          <ng-template ngbTabContent>
            <div class="py-3" *ngIf="!servicesPerTraveler[p.travelerReference]">
              No services available
            </div>
            <div class="pl-2" *ngIf="servicesPerTraveler[p.travelerReference]">
              <ngb-tabset #tabs="ngbTabset">
                <ngb-tab *ngIf="servicesPerTraveler[p.travelerReference]?.entireTrip?.length > 0" title="Entire Trip">
                  <ng-template ngbTabContent>
                    <ng-container
                      ngbTabContent
                      [ngTemplateOutlet]="serviceTable"
                      [ngTemplateOutletContext]="{
                        services: servicesPerTraveler[p.travelerReference]?.entireTrip,
                        passengerRef: p.travelerReference
                      }"
                    >
                    </ng-container>
                  </ng-template>
                </ngb-tab>
                <ngb-tab *ngIf="(servicesPerTraveler[p.travelerReference]?.perLeg | keyvalue)?.length > 0" title="Per Leg">
                  <ng-template  ngbTabContent>
                    <ngb-tabset #tabs="ngbTabset">
                      <ngb-tab
                        *ngFor="let route of (servicesPerTraveler[p.travelerReference]?.perLeg | keyvalue) | sortFlightsByRoutes: order.flights"
                        title="{{ route.key.replace('-', ' → ') }}"
                      >
                        <ng-template ngbTabContent>
                          <ng-container
                            [ngTemplateOutlet]="serviceTable"
                            [ngTemplateOutletContext]="{
                              services: route.value,
                              passengerRef: p.travelerReference
                            }"
                          >
                          </ng-container>
                        </ng-template>
                      </ngb-tab>
                    </ngb-tabset>
                  </ng-template>
                </ngb-tab>
                <ngb-tab title="Per Segment" *ngIf="(servicesPerTraveler[p.travelerReference]?.perSegment | keyvalue)?.length > 0">
                  <ng-template  ngbTabContent>
                    <ngb-tabset #tabs="ngbTabset">
                      <ngb-tab
                        *ngFor="let route of (servicesPerTraveler[p.travelerReference]?.perSegment | keyvalue) | sortFlightsByRoutes: segments: false"
                        title="{{ route.key.replace('-', ' → ') }}"
                      >
                        <ng-template ngbTabContent>
                          <ng-container
                            [ngTemplateOutlet]="serviceTable"
                            [ngTemplateOutletContext]="{
                              services: route.value,
                              passengerRef: p.travelerReference
                            }"
                          >
                          </ng-container>
                        </ng-template>
                      </ngb-tab>
                    </ngb-tabset>
                  </ng-template>
                </ngb-tab>
              </ngb-tabset>
            </div>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </ng-container>

    <ng-container *ngIf="step === 2 && selectedServices.length">
      <error-alert-block
        [error]="orderChangeError"
      ></error-alert-block>
      <div class="mb-2">New services:</div>
      <div class="table-responsive">
        <table class="table table-default mb-0">
          <thead>
          <tr>
            <th>Service</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Passenger</th>
            <th>Flight Segment</th>
            <th>Comment</th>
            <th *ngIf="isActiveOrder()">Action</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let srv of selectedServices">
            <td>{{ srv.name }}</td>
            <td>
              <ng-container *ngIf="srv.ref == 'ExcessBaggage'">
                <input
                  class="form-control"
                  type="number"
                  [min]="srv.minimumQuantity"
                  [max]="srv.maximumQuantity"
                  [(ngModel)]="srv.quantity"/>
              </ng-container>

              <ng-container *ngIf="srv.ref != 'ExcessBaggage'">
                {{ srv.quantity || 1 }}
              </ng-container>
            </td>
            <td>{{ srv | getTotalServicePrice }}</td>
            <td>{{ order.passengers | getPassengerNameByRefPipe:srv.travelerReferences }}</td>
            <td class="whitespace-pre-line">{{ order.flights | getSegmentRouteByIDPipe:srv.segmentReferences }}</td>
            <td>{{ srv.text }}</td>
            <td *ngIf="isActiveOrder()">
              <i class="material-symbols-outlined remove-icon fs-20"
                 title="Remove"
                 (click)="onRemoveService(srv, srv.travelerReferences)">
                delete
              </i>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
    <ng-container *ngIf="step === 3">
      <div class="payment-form-wp">
        <app-payment-form
          [validateTriggered]="validateTriggered"
          [paymentError]="orderChangeError"
          [paymentWarnings]="orderChangeWarnings"
          [allowedPaymentMethods]="order.allowedPaymentMethods"
          [cardSurchargesMap]="order.cardSurchargesMap"
          [totalPrice]='servicesTotalPrice'
          [logs]="order.logs"
        >
        </app-payment-form>
      </div>
    </ng-container>

    <ng-container *ngIf="step === 4">
      <app-payment-info
        [order]="order"
        [services]="selectedServices"
        [type]="'pay_ancillaries_on_orderchange'"
        [paymentError]="orderChangeError"
        (emitStepBack)="onStepBack()">
      </app-payment-info>
    </ng-container>

    <ng-container *ngIf="step === 5">
      <div class="mb-3" *ngIf="orderChangeWarnings.length">
        <app-show-warnings [warnings]="orderChangeWarnings"></app-show-warnings>
      </div>
      <div class="alert alert-success mb-0">
        Service item has been successfully ordered.
      </div>
    </ng-container>
  </div>

  <div class="card-footer text-right">
    <ng-container *ngIf="step === 1">
      <button
        type="button"
        class="btn btn-secondary mr-3"
        (click)="emitDismiss.emit(true);">Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="!selectedServicesCopy.length"
        (click)="showSelectedServices()">Next
      </button>
    </ng-container>

    <ng-container *ngIf="step === 2">
      <div class="btn mr-3" *ngIf="paymentLoader">
        <app-loader></app-loader>
      </div>
      <button
        type="button"
        class="btn btn-secondary mr-3"
        [disabled]="paymentLoader"
        (click)="setStep(1);">Back
      </button>
      <app-popover-wrapper
        popoverContent="Airline does not allow booking on hold."
        [disablePopover]="order.status === ORDER_STATUS.PENDING ||
                          order.status === ORDER_STATUS.TICKETED && order.allowedRequests?.ticketed?.AncillariesOnHold"
        [additionalClasses]="order.status !== ORDER_STATUS.PENDING ? 'd-inline-block mr-3' : 'd-inline-block'"
      >
        <button
          type="button"
          class="btn btn-tonal-primary"
          [disabled]="(order.status === ORDER_STATUS.TICKETED && !order.allowedRequests?.ticketed?.AncillariesOnHold) || paymentLoader"
          (click)="orderChange(true)">Book On Hold
        </button>
      </app-popover-wrapper>
      <app-popover-wrapper
        *ngIf="order.status !== ORDER_STATUS.PENDING"
        popoverContent="Your User Role does not have the permission to proceed with this action."
        [disablePopover]="ls.role !== ROLES.BOOKER"
        additionalClasses="d-inline-block"
      >
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="ls.role === ROLES.BOOKER"
          (click)="setStep(3)"
        >
          Book & Pay
        </button>
      </app-popover-wrapper>
    </ng-container>

    <ng-container *ngIf="step === 3">
      <div class="btn mr-3" *ngIf="paymentLoader">
        <app-loader></app-loader>
      </div>
      <button
        type="button"
        class="btn btn-secondary mr-3"
        [disabled]="paymentLoader"
        (click)="setStep(2)">Back
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="paymentLoader"
        (click)="proceedStep3()">Proceed
      </button>
    </ng-container>

    <ng-container *ngIf="step === 4">
      <div class="btn mr-3" *ngIf="paymentLoader">
        <app-loader></app-loader>
      </div>
      <button
        type="button"
        class="btn btn-secondary mr-3"
        [disabled]="paymentLoader"
        (click)="setStep(3)">Back
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="paymentLoader || paymentService.isNonePaymentMethod"
        (click)="orderChange()">Proceed
      </button>
    </ng-container>

    <ng-container *ngIf="step === 5">
      <button
        type="button"
        class="btn btn-primary mr-3"
        (click)="emitDismiss.emit(true);">OK
      </button>
    </ng-container>
  </div>
</div>

<ng-template #serviceTable let-services="services" let-passengerRef="passengerRef">
  <app-services-table [services]="services"
                      [passengerRef]="passengerRef"
                      [selectedServicesCopy]="selectedServicesCopy"
                      [selectedServicesMapCopy]="selectedServicesMapCopy"
                      [validationErrors]="validationErrors"
                      (emitUpdatedValues)="onEmitUpdatedValues($event)">
  </app-services-table>
</ng-template>

