<div class="container mt-3">
  <div class="row">
    <div class="col">
      <div class="mt-3">
        <ng-container *ngIf="responseWarning.length">
          <div #warnings class="alert alert-warning p-3 mb-3 rounded warnings"
               *ngIf="showWarnings"
               [ngStyle]="{'max-height': !allWarningsAreShown ? '190px' : 'fit-content' }" [class.faded]="!allWarningsAreShown">
            <ul>
              <li *ngFor="let item of responseWarning"
                  [innerHTML]="item | htmlToStringParser | replace: '\n':'<br\/>'">
              </li>
            </ul>
          </div>
          <div class="d-flex justify-content-end mb-3">
            <div class="btn btn-secondary p-2"
                 [class.mr-3]="isWarningsReadBtnNeeded && showWarnings"
                 (click)="toggleDisplayWarnings()">
              {{showWarnings ? 'Hide' : 'Show warnings'}}
            </div>
            <div class="btn btn-secondary p-2"
                 *ngIf="isWarningsReadBtnNeeded && showWarnings"
                 (click)="toggleReadWarnings()">
              Read {{!allWarningsAreShown ? 'more' : 'less'}}
            </div>
          </div>
        </ng-container>
        <div *ngIf="responseError && !responseError.message">
          <div class="alert-danger p-3 mb-3 rounded">
            {{ responseError }}
          </div>
          <div class="retry-line p-3 mb-3">
            <button
              type="button"
              class="btn btn-primary"
              (click)="refresh()">
              Refresh the page
            </button>
          </div>
        </div>
        <div *ngIf="responseError.message">
          <div class="alert-{{ responseError.type }} p-3 mb-3 rounded">
            {{ responseError.message }}
          </div>
          <div class="retry-line p-3 mb-3">
            <button
              type="button"
              class="btn btn-primary"
              [routerLink]="['/search']">
              Close
            </button>
          </div>
        </div>
        <div class="loader-wrapper" *ngIf="showLoader">
          <app-loader></app-loader>
        </div>

        <div class="offering card" *ngIf="offer && !showLoader && !responseError">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col d-flex align-items-center">
                <div class="filter-image">
                  <app-airline-img
                    [airlineID]="offer.owner"
                    [title]="offer.owner | getImageDescription: offer.flights"
                  ></app-airline-img>
                </div>
                <div class="text-uppercase fw-500">
                  {{helpers.allProvidersObj[offer.owner]}}
                </div>
              </div>
              <div class="col">
                <div class="text-nowrap" *ngFor="let flight of offer.flights; last as last">
                  <span class="fw-500" [appTooltip]="{airportCode: flight.departure.airportCode, terminalName: flight.departure.terminalName}">
                      {{flight.departure.airportCode}}
                  </span>
                  →
                  <span class="fw-500" [appTooltip]="{airportCode: flight.arrival.airportCode, terminalName: flight.arrival.terminalName}">
                      {{flight.arrival.airportCode}}
                  </span>

                  <span class="ml-2 gray-700-color">Class:</span>
                  <span class="fw-500" *ngFor="let segment of flight.segments; last as last">
                    {{segment.detail.classOfService.code}}{{!last ? ',' : ''}}
                  </span>
                </div>
              </div>
              <div class="col">
                <div *ngIf="travelers !== '{}'">
                  <span class="gray-700-color">PAX: </span>
                  <ng-container *ngFor="let traveler of travelers | pairs; first as first">
                    <span class="fw-500" *ngIf="traveler[1]">{{!first ? ', ' : ''}}{{traveler[1]}} {{traveler[0].toUpperCase()}}</span>
                  </ng-container>
                </div>
              </div>
              <div class="col">
                <div class="float-right">
                  <app-show-pax-breakdown-info
                    [consumerPrice]="offer.price.consumer"
                    [passengers]="offer.passengers">
                    <div class="bigger-price text-nowrap">
                      <span class="font-weight-bold">{{offer.price.consumer.total | number :'1.2-2'}}</span>
                      {{offer.price.consumer.currency}}
                    </div>
                  </app-show-pax-breakdown-info>
                  <app-show-taxes-info
                    [taxesInfo]="offer.price.consumer.tax.breakdown"
                    [currency]="offer.price.consumer.currency">
                    <div class="smaller-price">
                      Taxes: {{offer.price.consumer.tax.total | number :'1.2-2'}} {{offer.price.consumer.currency}}
                    </div>
                  </app-show-taxes-info>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body" [ngSwitch]="selectedStep" [formGroup]="form">
            <ng-container *ngSwitchCase="bookingSteps.OFFER">
              <app-offer-price-view
                [offer]="offer"
                [travelers]="travelers"
                [fareRules]="fareRules"
                [isShowDisclosureTable]="true"
                [useNewDesign]="true"
                [seatsPerSegment]="showSeats"
                (emitRemoveSeat)="onRemoveSeat($event)"
              ></app-offer-price-view>
            </ng-container>
            <ng-container *ngSwitchCase="bookingSteps.REMARKS">

              <ng-container *ngIf="corporateRemarkTemplates.length; else agencyRemarks">
                <ng-container *ngIf="agencyRemarkTemplates.length">
                  <h3 class="text-center mb-0">
                    <span class="remark-name">Agency</span> Remarks <span class="text-primary" *ngIf="hasMandatoryRemarks">*</span>
                  </h3>
                  <ng-container [ngTemplateOutlet]="agencyRemarks"></ng-container>
                </ng-container>
                <h3 class="text-center mb-0" [class.mt-5]="agencyRemarkTemplates.length">
                  <span class="remark-name">Corporate</span> Remarks <span class="text-primary" *ngIf="hasMandatoryRemarks">*</span>
                </h3>
                <app-remarks
                  (emitUpdatedRemark)="updateCorporateRemarks($event)"
                  (emitTemplateSelected)="isCorporateRemarksTemplateSelected = $event"
                  [remark]="corporateRemarksData || offer.remarks"
                  [templates]="corporateRemarkTemplates"
                  [orderId]="offer.id"
                  [orderInfo]="offerInfo"
                  [isRemarksAfterOrderCreation]="false"
                  [mandatoryRemarks]="mandatoryCorporateRemarks"
                  [saveTriggered]="remarksSaveTriggered"
                ></app-remarks>
              </ng-container>

              <ng-template #agencyRemarks>
                <app-remarks
                  (emitUpdatedRemark)="updateAgencyRemarks($event)"
                  (emitTemplateSelected)="isAgencyRemarksTemplateSelected = $event"
                  [remark]="agencyRemarksData || offer.remarks"
                  [templates]="agencyRemarkTemplates"
                  [orderId]="offer.id"
                  [orderInfo]="offerInfo"
                  [isRemarksAfterOrderCreation]="false"
                  [mandatoryRemarks]="mandatoryAgencyRemarks"
                  [saveTriggered]="remarksSaveTriggered"
                ></app-remarks>
              </ng-template>
            </ng-container>
            <ng-container *ngSwitchCase="bookingSteps.PAX">
              <div class="corporate">
                <div class="d-flex align-items-center justify-content-between mb-3">
                  <div class="d-flex align-items-center gap-10">
                    <span class="material-symbols-outlined blue-color">
                      corporate_fare
                    </span>
                    <h3 class="mb-0 h3-label">Corporate</h3>
                  </div>
                  <div class="expand-icon-wrapper" (click)="isCorporateOpened = !isCorporateOpened">
                    <span class="material-symbols-outlined" [@rotate]="!isCorporateOpened">
                      expand_less
                    </span>
                  </div>
                </div>
                <div class="card nested-card overflow-unset" [@collapse]="!isCorporateOpened">
                  <div class="card-body">
                    <div class="form-row">
                      <div class="col">
                        <button *ngIf="!corporateSelectionState"
                                class="btn btn-tonal-primary"
                                title="Add Address"
                                (click)="corporateSelectionState = true">
                          <i class='fa fa-plus w-10 fs-10'></i>
                        </button>
                      </div>
                    </div>
                    <ng-container *ngIf="corporateSelectionState && isCorporateOpened">
                      <div *ngIf="ls.profilesType === 'umbrella'; else corporate">
                        <div class="form-row">
                          <div [ngClass]="disableCloseCompany ? 'col-lg-3 col-md-5' : 'col-lg-4 col-md-6'">
                            <div class="form-row">
                              <div *ngIf="!umbrellaCompanyShow && !selectedCompany"
                                   class="col-sm-10" [ngClass]="disableCloseCompany ? 'col' : 'col-sm-10'">
                                <label>&nbsp;</label>
                                <app-umbrella-bar (emitIsSelectedCompany)="onSelectedCompany($event)"></app-umbrella-bar>
                              </div>
                              <div *ngIf="selectedCompany"
                                   class="selected-corporate" [ngClass]="disableCloseCompany ? 'col' : 'col-sm-10'">
                                <div class="is-selected-corporate" *ngIf="!isChangeCompany">
                                  <img src="assets/img/umbrella/umbrella-logo.svg"
                                       class="hs-image-widget umbrella-icon">
                                  <span class="selected-info">Corporate: </span>
                                  <span class="selected-corporate-span">
                                    <span class="click-select">{{selectedCompany}}</span>
                                  </span>
                                </div>
                                <div *ngIf="isChangeCompany" class="umbrella-line">
                                  <app-umbrella-bar (emitIsSelectedCompany)="onSelectedCompany($event)"></app-umbrella-bar>
                                </div>
                              </div>
                              <div class="col" *ngIf="!disableCloseCompany">
                            <label>&nbsp;</label>
                            <i class="material-symbols-outlined d-flex align-items-center remove-icon h-40 fs-20"
                               title="Remove"
                               (click)="onSelectedCompany(null);">
                              delete
                            </i>
                          </div>
                            </div>
                          </div>
                          <ng-container [ngTemplateOutlet]="loyaltyProgramAccountTmpl"></ng-container>
                        </div>
                      </div>
                    </ng-container>
                    <ng-template #corporate>
                      <div class="form-row">
                        <div [ngClass]="disableCloseCorporate ? 'col-lg-3 col-md-5' : 'col-lg-4 col-md-6'">
                          <div class="form-row">
                            <div *ngIf="!corporateShow && !selectedCorporate && ls.profilesType === 'airgateway'"
                                 [ngClass]="disableCloseCorporate ? 'col' : 'col-sm-10'">
                              <label>&nbsp;</label>
                              <app-profiles-bar (emitIsSelectedCompany)="onSelectedCorporate($event)"></app-profiles-bar>
                            </div>
                            <div *ngIf="selectedCorporate && ls.profilesType === 'airgateway'"
                                 class="selected-corporate" [ngClass]="disableCloseCorporate ? 'col' : 'col-sm-10'">
                              <div class="is-selected-corporate" *ngIf="!isChangeCorporate">
                                <span class="selected-info">Corporate: </span>
                                <span class="selected-corporate-span">
                                  <span class="click-select">{{selectedCorporate}}</span>
                                </span>
                              </div>
                              <div *ngIf="isChangeCorporate" class="umbrella-line">
                                <app-profiles-bar (emitIsSelectedCompany)="onSelectedCorporate($event)"></app-profiles-bar>
                              </div>
                            </div>
                            <div class="col-sm-2" *ngIf="!disableCloseCorporate">
                              <label>&nbsp;</label>
                              <i class="material-symbols-outlined d-flex align-items-center remove-icon h-40 fs-20"
                                 title="Remove"
                                 (click)="onSelectedCorporate(null);">
                                delete
                              </i>
                            </div>
                          </div>
                        </div>
                        <ng-container [ngTemplateOutlet]="loyaltyProgramAccountTmpl"></ng-container>
                      </div>
                    </ng-template>

                    <ng-template #loyaltyProgramAccountTmpl>
                      <div class="col-lg-3 col-md-5" *ngIf="isLoyaltyProgramAccountEnabled()">
                        <label>Corporate Loyalty Program</label>
                        <input
                          *ngIf="!loyaltyProgramAccounts.length"
                          [validate]="form.get('loyaltyProgramAccount').dirty || validateTriggered"
                          type="text"
                          formControlName="loyaltyProgramAccount"
                          autocomplete="false"
                          placeholder="OBXXXXX..."
                          class="form-control">

                        <ng-container *ngIf="loyaltyProgramAccounts.length">
                          <input type="text"
                                 placeholder="OBXXXXX..."
                                 id="selectGroups"
                                 class="form-control corporate-loyalty-program-control custom-select"
                                 formControlName="loyaltyProgramAccount"
                                 [validate]="form.get('loyaltyProgramAccount').dirty || validateTriggered"
                                 list="loyaltyProgramAccountsList">
                          <datalist id="loyaltyProgramAccountsList">
                            <option *ngFor="let account of loyaltyProgramAccounts" [value]="account">{{ account }}
                          </datalist>
                        </ng-container>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>

              <div class="d-flex align-items-center gap-10 mb-3">
                <span class="material-symbols-outlined blue-color">
                  person
                </span>
                <h3 class="mb-0 h3-label">Passengers</h3>
              </div>
              <ngb-tabset  #tabs="ngbTabset" [activeId]="firstTravelerID"
                           [destroyOnHide]="false" class="passengers-tabset">
                <ng-container *ngFor="let travelerType of (travelers | pairs)">
                  <ng-container *ngFor="let idx of seatMapService.createRange(travelerType[1]);">
                    <ngb-tab
                      *ngIf="('passenger_' + travelerType[0] + '_' + idx) as psgKey"
                      id="{{ psgKey }}"
                      [title]="travelerType[0].toUpperCase() + ' ' +  (idx + 1)"
                    >
                      <ng-template ngbTabTitle>
                        <i *ngIf="form.get(psgKey).status === 'INVALID'" class="material-icons remark-icon">
                          error_outline
                        </i>
                        <div *ngIf="form.get(psgKey).status === 'VALID'" class="successful-mark-icon">
                        </div>
                      </ng-template>
                      <ng-template ngbTabContent>
                        <div [formGroupName]="psgKey" class="sentry-mask needs-validation">
                          <div class="ml-3 mb-2 d-flex align-items-center justify-content-between">
                            <h4 class="h4-label">Details</h4>
                            <div class="expand-icon-wrapper" (click)="togglePassengerBlock(isPassengerDetailsOpened, psgKey)">
                              <span class="material-symbols-outlined fs-20" [@rotate]="!isPassengerDetailsOpened[psgKey]">
                                expand_less
                              </span>
                            </div>
                          </div>
                          <div class="card nested-card overflow-unset" [@collapse]="!isPassengerDetailsOpened[psgKey]">
                            <div class="card-body">
                              <div class="form-row" formGroupName="data">
                                <div class="form-group-sm col-lg-3 col-md-5"
                                     *ngIf="form.get(psgKey + '.data.name') as name">
                                  <label><span class="text-primary"><b>*</b></span> Given Name / First name</label>
                                  <ng-container *ngIf="(!umbrellaCompanyShow || !(ls.umbrellaToken && ls.profilesType === 'umbrella')) && !corporateShow">
                                    <input [validate]="name.dirty || validateTriggered"
                                           type="text"
                                           formControlName="name"
                                           class="form-control"
                                           autocomplete="false"
                                           placeholder="Alex"/>
                                  </ng-container>
                                  <ng-container *ngIf="umbrellaCompanyShow && ls.umbrellaToken && ls.profilesType === 'umbrella'">
                                    <app-search-umbrella-travellers
                                      [isValid]="!name.invalid && name.dirty"
                                      [isInvalid]="name.invalid && name.dirty"
                                      [travellerInputValue]="this.form.value[psgKey]?.data?.name"
                                      [isDisabled]="isChangeCompany"
                                      [passengerFormKey]="psgKey"
                                      (emitPsgFormData)="onEmitUmbrellaPsgFormData($event, travelerType[0].toUpperCase())"
                                      (emitSearchString)="onChangeInputValue($event, 'name', psgKey)"
                                    ></app-search-umbrella-travellers>
                                  </ng-container>
                                  <ng-container *ngIf="corporateShow && ls.profilesType === 'airgateway'">
                                    <app-search-profiles
                                      [isValid]="!name.invalid && name.dirty"
                                      [isInvalid]="name.invalid && name.dirty"
                                      [inputValue]="this.form.value[psgKey]?.data?.name"
                                      [isDisabled]="isChangeCorporate"
                                      [passengerKey]="psgKey"
                                      (emitProfileData)="onEmitProfilePsgFormData($event, travelerType[0].toUpperCase())"
                                      (emitSearchValue)="onChangeInputValueFromProfile($event, 'name', psgKey)"
                                    ></app-search-profiles>
                                  </ng-container>
                                </div>
                                <div class="form-group-sm col-lg-3 col-md-5"
                                     *ngIf="form.get(psgKey + '.data.surname') as surname">
                                  <label><span class="text-primary"><b>*</b></span> Surname / Last name</label>
                                  <ng-container *ngIf="(!umbrellaCompanyShow || !(ls.umbrellaToken && ls.profilesType === 'umbrella')) && !corporateShow">
                                    <input [validate]="surname.dirty || validateTriggered"
                                           type="text"
                                           formControlName="surname"
                                           class="form-control"
                                           autocomplete="false"
                                           placeholder="Crow"/>
                                  </ng-container>
                                  <ng-container *ngIf="umbrellaCompanyShow && ls.umbrellaToken && ls.profilesType === 'umbrella'">
                                    <app-search-umbrella-travellers
                                      [isValid]="!surname.invalid && surname.dirty"
                                      [isInvalid]="surname.invalid && surname.dirty"
                                      [travellerInputValue]="this.form.value[psgKey]?.data?.surname"
                                      [isDisabled]="isChangeCompany"
                                      [noInputFocus]="true"
                                      [passengerFormKey]="psgKey"
                                      (emitPsgFormData)="onEmitUmbrellaPsgFormData($event, travelerType[0].toUpperCase())"
                                      (emitSearchString)="onChangeInputValue($event, 'surname', psgKey)"
                                    ></app-search-umbrella-travellers>
                                  </ng-container>
                                  <ng-container *ngIf="corporateShow && ls.profilesType === 'airgateway'">
                                    <app-search-profiles
                                      [isValid]="!surname.invalid && surname.dirty"
                                      [isInvalid]="surname.invalid && surname.dirty"
                                      [inputValue]="this.form.value[psgKey]?.data?.surname"
                                      [isDisabled]="isChangeCorporate"
                                      [noInputFocus]="true"
                                      [passengerKey]="psgKey"
                                      (emitProfileData)="onEmitProfilePsgFormData($event, travelerType[0].toUpperCase())"
                                      (emitSearchValue)="onChangeInputValueFromProfile($event, 'surname', psgKey)"
                                    ></app-search-profiles>
                                  </ng-container>
                                </div>
                              </div>

                              <div class="form-row" formGroupName="data">
                                <div class="form-group-sm col-lg-3 col-md-5" *ngIf="form.get(psgKey + '.data.title') as title">
                                  <label for="title"><span class="text-primary"><b>*</b></span> Title</label>
                                  <ng-select2
                                    [validate]="title.dirty || validateTriggered"
                                    formControlName="title"
                                    class="ng-select2-form-control d-flex"
                                    [placeholder]="'Select title ...'"
                                    (click)="helpers.setFocusToSelect2SearchField()"
                                    (valueChanged)="onSelectTitle($event, psgKey)"
                                    id="title"
                                  >
                                    <option *ngFor="let availableTitle of titles" [value]="availableTitle">{{ availableTitle | uppercase }}
                                  </ng-select2>
                                </div>
                                <ng-container *ngIf="!isGenderExists[psgKey]">
                                  <div class="form-group-sm col-lg-3 col-md-5" *ngIf="form.get(psgKey + '.data.gender') as gender">
                                    <label><span class="text-primary"><b>*</b></span> Gender</label>
                                    <div class="radio-container">
                                      <div class="custom-control custom-radio custom-control-inline" *ngFor="let gen of genders">
                                        <input [validate]="gender.dirty || validateTriggered"
                                               type="radio"
                                               [value]="gen"
                                               formControlName="gender"
                                               class="form-control custom-control-input"
                                               id="{{(psgKey + '_' + gen)}}"
                                        >
                                        <label class="custom-control-label" for="{{(psgKey + '_' + gen)}}">{{gen}}</label>
                                      </div>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>

                              <div class="form-row" formGroupName="data">
                                <div class="col-lg-3 col-md-5" *ngIf="form.get(psgKey + '.data.birthdate') as birthdate">
                                  <label class="d-flex align-items-center gap-5">
                                    Date of birth
                                    <i class="material-icons icon info"
                                       [ngbPopover]="'Date can be added by using the specified format or by browsing on calendar'"
                                       popoverClass="info-popover"
                                       [triggers]="'hover'"
                                    >
                                      info_outline
                                    </i>
                                  </label>
                                  <div class="datepicker-container">
                                    <input
                                      appDateInput
                                      [validate]="birthdate.dirty || validateTriggered"
                                      [minMaxDateOfBirth]="minMaxDateOfBirth[(travelerType[0] | travelerTypePipe).toUpperCase()]"
                                      autocomplete="false"
                                      formControlName="birthdate"
                                      [placement]="'bottom'"
                                      ngbDatepicker
                                      [minDate]="fullMinMaxDateOfBirth[(travelerType[0] | travelerTypePipe).toUpperCase()]?.minDate"
                                      [maxDate]="fullMinMaxDateOfBirth[(travelerType[0] | travelerTypePipe).toUpperCase()]?.maxDate"
                                      #d="ngbDatepicker"
                                      (click)="d.close()"
                                      (ngModelChange)="onBirthdateChanged($event, psgKey, travelerType[0], popover)"
                                      class="form-control"
                                      [class.warning]="dateWarningsByPassenger[psgKey]"
                                      placeholder="DD-MM-YY"
                                      [ngbPopover]="dateWarningsByPassenger[psgKey]"
                                      popoverClass="date-popover"
                                      #popover="ngbPopover"
                                      triggers="hover"
                                      autoclose="outside"
                                    />
                                    <button (click)="d.toggle()">
                                      <i class="material-icons" style='color: white'>
                                        event
                                      </i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="ml-3 mb-2 d-flex align-items-center justify-content-between">
                            <h4 class="h4-label">Contact</h4>
                            <div class="expand-icon-wrapper" (click)="togglePassengerBlock(isPassengerContactOpened, psgKey)">
                              <span class="material-symbols-outlined fs-20" [@rotate]="!isPassengerContactOpened[psgKey]">
                                expand_less
                              </span>
                            </div>
                          </div>
                          <div class="card nested-card overflow-unset" [@collapse]="!isPassengerContactOpened[psgKey]">
                            <div class="card-body">
                              <div class="form-row" formGroupName="data">
                                <ng-container *ngIf="form.get(psgKey + '.data.email') as email">
                                  <div class="col-lg-3 col-md-5">
                                    <label class="d-flex align-items-center gap-5">
                                      <span>
                                        <span class="text-primary" *ngIf="firstTravelerID === psgKey"><b>*</b></span>
                                        Email
                                      </span>
                                      <i class="material-icons icon info"
                                         *ngIf="firstTravelerID === psgKey"
                                         [ngbPopover]="'The Provider might send information related to the order to this email address'"
                                         popoverClass="info-popover"
                                         [triggers]="'hover'">
                                        info_outline
                                      </i>
                                    </label>

                                    <ng-container *ngIf="(!umbrellaCompanyShow || !(ls.umbrellaToken && ls.profilesType === 'umbrella')) && !corporateShow">
                                      <input [validate]="email.dirty || validateTriggered"
                                             type="text"
                                             formControlName="email"
                                             autocomplete="false"
                                             class="form-control"
                                             placeholder="web@example.com"/>
                                    </ng-container>
                                    <ng-container *ngIf="umbrellaCompanyShow && ls.umbrellaToken && ls.profilesType === 'umbrella'">
                                      <app-search-umbrella-travellers
                                        [isValid]="!email.invalid && email.dirty"
                                        [isInvalid]="email.invalid && email.dirty"
                                        [travellerInputValue]="this.form.value[psgKey]?.data?.email"
                                        [isDisabled]="isChangeCompany"
                                        [target]="'email'"
                                        [noInputFocus]="true"
                                        [passengerFormKey]="psgKey"
                                        (emitPsgFormData)="onEmitUmbrellaPsgFormData($event, travelerType[0].toUpperCase())"
                                        (emitSearchString)="onChangeInputValue($event, 'email', psgKey)"
                                      ></app-search-umbrella-travellers>
                                    </ng-container>
                                    <ng-container *ngIf="corporateShow && ls.profilesType === 'airgateway'">
                                      <app-search-profiles
                                        [isValid]="!email.invalid && email.dirty"
                                        [isInvalid]="email.invalid && email.dirty"
                                        [inputValue]="this.form.value[psgKey]?.data?.email"
                                        [isDisabled]="isChangeCorporate"
                                        [target]="'email'"
                                        [noInputFocus]="true"
                                        [passengerKey]="psgKey"
                                        (emitProfileData)="onEmitProfilePsgFormData($event, travelerType[0].toUpperCase())"
                                        (emitSearchValue)="onChangeInputValueFromProfile($event, 'email', psgKey)"
                                      ></app-search-profiles>
                                    </ng-container>
                                  </div>
                                </ng-container>
                                <div class="col-lg-3 col-md-5" formGroupName="phone">
                                  <label><span class="text-primary" *ngIf="firstTravelerID === psgKey"><b>*</b></span> Phone</label>
                                  <input
                                    [validate]="form.get(psgKey + '.data.phone.number').dirty || validateTriggered"
                                    (countryChange)="onCountryChange(psgKey, $event)"
                                    (intlTelInputObject)="telInputObject(psgKey, $event)"
                                    (ng2TelOutput)="getNumber($event)"
                                    [ng2TelInputOptions]="helpers.defaultTelInputOptions"
                                    class="form-control"
                                    formControlName="number"
                                    ng2TelInput>
                                </div>
                              </div>
                            </div>
                          </div>

                          <ng-container *ngIf="offer.allowedExtraFields?.frequentFlyerNumber && travelerType[0].toUpperCase() !== 'INF'">
                            <div class="ml-3 mb-2 d-flex align-items-center justify-content-between">
                              <h4 class="h4-label">FFN</h4>
                              <div class="expand-icon-wrapper" (click)="togglePassengerBlock(isPassengerFfnOpened, psgKey)">
                                <span class="material-symbols-outlined fs-20" [@rotate]="!isPassengerFfnOpened[psgKey]">
                                  expand_less
                                </span>
                              </div>
                            </div>
                            <div class="card nested-card" [@collapse]="!isPassengerFfnOpened[psgKey]">
                              <div class="card-body">
                                <div formGroupName="data" *ngIf="ffnSelectionState[psgKey]">
                                  <div formGroupName="fqtvInfo" *ngIf="form.get(psgKey + '.data.fqtvInfo') as fqtvInfoControl">
                                    <div class="form-row">
                                      <div class="col-lg-3 col-md-5">
                                        <ng-select2
                                          [validate]="fqtvInfoControl.get('airlineID').dirty ||
                                              fqtvInfoControl.get('account.number').dirty || validateTriggered"
                                          class="ng-select2-form-control"
                                          formControlName="airlineID"
                                          [placeholder]="'Select Airline Designator ...'"
                                          (click)="helpers.setFocusToSelect2SearchField()"
                                          allowClear
                                        >
                                          <option *ngFor="let c of (dictionaryAirlineDesignators | pairs)" [value]="c[0]">{{ c[1] }}
                                        </ng-select2>
                                      </div>
                                      <div class="col-lg-3 col-md-5" formGroupName="account" *ngIf="form.get(psgKey + '.data.fqtvInfo.account.number') as number">
                                        <div class="input-group h-40">
                                          <input [validate]="number.dirty || validateTriggered"
                                                 formControlName="number"
                                                 placeholder="Frequent Flyer Number"
                                                 type="text"
                                                 class="form-control"/>
                                        </div>
                                      </div>
                                      <div class="col">
                                        <i class="material-symbols-outlined h-100 d-flex align-items-center remove-icon fs-20"
                                           title="Remove"
                                           (click)="resetFFN(psgKey)">
                                          delete
                                        </i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <button *ngIf="!ffnSelectionState[psgKey]"
                                        class="btn btn-tonal-primary"
                                        title="Add FFN"
                                        (click)="ffnSelectionState[psgKey] = true">
                                  <i class='fa fa-plus w-10 fs-10'></i>
                                </button>
                              </div>
                            </div>
                          </ng-container>

                          <ng-container *ngIf="checkIsFirstPassenger(psgKey) || corporateShow">
                            <div class="ml-3 mb-2 d-flex align-items-center justify-content-between">
                              <h4 class="h4-label">Address</h4>
                              <div class="expand-icon-wrapper" (click)="togglePassengerBlock(isPassengerAddressOpened, psgKey)">
                                <span class="material-symbols-outlined fs-20" [@rotate]="!isPassengerAddressOpened[psgKey]">
                                  expand_less
                                </span>
                              </div>
                            </div>
                            <div class="card nested-card" [@collapse]="!isPassengerAddressOpened[psgKey]">
                              <div class="card-body">
                                <div class="form-row" *ngIf="addresses.home || addresses.destination">
                                  <div class="col-lg-6" *ngIf="addresses.home">
                                    <ng-container formGroupName="data">
                                      <div class="form-row mb-3">
                                        <div class="d-flex align-items-center gap-20 col-12">
                                          <div class="address">
                                            Home address
                                          </div>
                                          <i class="material-symbols-outlined remove-icon fs-20"
                                             title="Remove"
                                             (click)="removeAddress('home', psgKey + '.data.address')">
                                            delete
                                          </i>
                                        </div>
                                      </div>
                                      <ng-container formGroupName="address">
                                        <div class="form-row">
                                          <div class="form-group col-lg-6 col-md-10">
                                            <label>Country</label>
                                            <ng-select2
                                              formControlName="countryCode"
                                              [validate]="form.get(psgKey + '.data.address.countryCode').dirty || validateTriggered"
                                              class="ng-select2-form-control"
                                              [placeholder]="'Select country ...'"
                                              (click)="helpers.setFocusToSelect2SearchField()"
                                            >
                                              <option *ngFor="let c of (countries | pairs)" [value]="c[0]">{{ c[1] }}
                                              </option>
                                            </ng-select2>
                                          </div>
                                          <div class="form-group col-md-4">
                                            <label>City</label>
                                            <input
                                              type="text"
                                              formControlName="cityName"
                                              [validate]="form.get(psgKey + '.data.address.cityName').dirty || validateTriggered"
                                              placeholder="Madrid"
                                              autocomplete="false"
                                              class="form-control">
                                          </div>
                                        </div>
                                        <div class="form-row">
                                          <div class="form-group col-md-4">
                                            <label>Postal code</label>
                                            <input
                                              type="text"
                                              formControlName="postalCode"
                                              [validate]="form.get(psgKey + '.data.address.postalCode').dirty || validateTriggered"
                                              placeholder="28001"
                                              autocomplete="false"
                                              class="form-control ">
                                          </div>
                                          <div class="form-group col-md-4">
                                            <label>Street</label>
                                            <div class="form-row">
                                              <div class="col">
                                                <input
                                                  type="text"
                                                  formControlName="street"
                                                  [validate]="form.get(psgKey + '.data.address.street').dirty || validateTriggered"
                                                  placeholder="Gran Via"
                                                  autocomplete="false"
                                                  class="form-control">
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </ng-container>
                                    </ng-container>
                                  </div>

                                  <ng-container formGroupName="data" *ngIf="addresses.destination">
                                    <ng-container formGroupName="addresses">
                                      <ng-container *ngFor="let control of form.get(psgKey + '.data.addresses')['controls']; let idx = index" [formGroupName]="idx">
                                        <div class="col-lg-6">
                                          <div class="form-row mb-3">
                                            <div class="d-flex align-items-center gap-20 col-12">
                                              <div class="address">
                                                Destination address <ng-container *ngIf="form.get(psgKey + '.data.addresses')['controls']?.length > 1">{{idx + 1}}</ng-container>
                                              </div>
                                              <i class="material-symbols-outlined remove-icon fs-20"
                                                 title="Remove"
                                                 (click)="removeAddress('destination', psgKey + '.data.addresses', idx)">
                                                delete
                                              </i>
                                            </div>
                                          </div>
                                          <div class="form-row">
                                            <div class="form-group col-lg-6 col-md-10">
                                              <label>Country</label>
                                              <ng-select2
                                                formControlName="countryCode"
                                                [validate]="control.get('countryCode').dirty || validateTriggered"
                                                class="ng-select2-form-control"
                                                [placeholder]="'Select country ...'"
                                                (click)="helpers.setFocusToSelect2SearchField()"
                                              >
                                                <option *ngFor="let c of (countries | pairs)" [value]="c[0]">{{ c[1] }}
                                                </option>
                                              </ng-select2>
                                            </div>
                                            <div class="form-group col-md-4">
                                              <label>City</label>
                                              <input
                                                type="text"
                                                formControlName="cityName"
                                                [validate]="control.get('cityName').dirty || validateTriggered"
                                                placeholder="Madrid"
                                                autocomplete="false"
                                                class="form-control">
                                            </div>
                                          </div>
                                          <div class="form-row">
                                            <div class="form-group col-md-4">
                                              <label>Postal code</label>
                                              <input
                                                type="text"
                                                formControlName="postalCode"
                                                [validate]="control.get('postalCode').dirty || validateTriggered"
                                                placeholder="28001"
                                                autocomplete="false"
                                                class="form-control">
                                            </div>
                                            <div class="form-group col-md-4">
                                              <label>Street</label>
                                              <div class="form-row">
                                                <div class="col">
                                                  <input
                                                    type="text"
                                                    formControlName="street"
                                                    [validate]="control.get('street').dirty || validateTriggered"
                                                    placeholder="Gran Via"
                                                    autocomplete="false"
                                                    class="form-control">
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </ng-container>
                                    </ng-container>
                                  </ng-container>
                                </div>

                                <div class="form-row" *ngIf="addressSelectionState[psgKey]">
                                  <div class="col-lg-3 col-md-5 col-sm-11">
                                    <ng-select2
                                      [placeholder]="'Address Type'"
                                      (click)="helpers.setFocusToSelect2SearchField()"
                                      class="ng-select2-control select2-control"
                                      (valueChanged)="addAddress($event, psgKey)"
                                    >
                                      <option value="">Add address</option>
                                      <option value="home" *ngIf="!addresses.home">Home</option>
                                      <option value="destination">Destination</option>
                                    </ng-select2>
                                  </div>
                                  <div class="ml-1 h-40 d-flex align-items-center">
                                    <i class="material-symbols-outlined remove-icon fs-20"
                                       title="Remove"
                                       (click)="addressSelectionState[psgKey] = false">
                                      delete
                                    </i>
                                  </div>
                                </div>

                                <button *ngIf="!addressSelectionState[psgKey]"
                                        class="btn btn-tonal-primary"
                                        title="Add Address"
                                        (click)="addressSelectionState[psgKey] = true">
                                  <i class='fa fa-plus w-10 fs-10'></i>
                                </button>
                              </div>
                            </div>
                          </ng-container>

                          <ng-container *ngIf="(offer.passengers | excludePassengersPipe: true : 'ADT') as adultPassengers">
                            <ng-container *ngIf="adultPassengers.length > 1 && travelerType[0].toUpperCase() === 'INF'">
                              <div class="ml-3 mb-2 d-flex align-items-center justify-content-between">
                                <h4 class="h4-label">Adult reference</h4>
                                <div class="expand-icon-wrapper" (click)="togglePassengerBlock(isPassengerAdultReferenceOpened, psgKey)">
                                  <span class="material-symbols-outlined fs-20" [@rotate]="!isPassengerAdultReferenceOpened[psgKey]">
                                    expand_less
                                  </span>
                                </div>
                              </div>
                              <div class="card nested-card" [@collapse]="!isPassengerAdultReferenceOpened[psgKey]">
                                <div class="card-body">
                                  <div class="form-row">
                                    <div class="col-lg-3 col-md-5" *ngIf="form.get(psgKey + '.adultReference') as adultReference">
                                      <select formControlName="adultReference"
                                              [validate]="adultReference.dirty || validateTriggered"
                                              required
                                              class="form-control"
                                              (change)="onAdultReferenceChange($event.target.value, idx, psgKey)">
                                        <option value="" hidden>Select adult reference...</option>
                                        <option value="clear" [hidden]="!adultReference.value">Clear reference</option>
                                        <option *ngFor="let passenger of adultPassengers; let idx = index"
                                                [value]="passenger.travelerReference"
                                                [hidden]="adultReferences.includes(passenger.travelerReference)">
                                          (ADT {{idx + 1}}) {{form.get('passenger_adt_' + idx + '.data.name')?.value}} {{form.get('passenger_adt_' + idx + '.data.surname')?.value}}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </ng-container>

                          <div class="ml-3 mb-2 d-flex align-items-center justify-content-between">
                            <h4 class="h4-label">
                              <div class="d-flex align-items-center gap-5">
                                <span placement="top" ngbTooltip="These fields are mandatory in some countries and airlines">
                                  Documents
                                </span>
                                <i *ngIf="isSecureFlight"
                                   class="material-icons icon info"
                                   [ngbPopover]="'This is a secure flight. Passport/National ID might be required by the provider before issuing. Please keep in mind we don\'t support passenger updates with all providers.'"
                                   popoverClass="info-popover"
                                   [triggers]="'hover'"
                                >
                                  info_outline
                                </i>
                              </div>
                            </h4>
                            <div class="expand-icon-wrapper" (click)="togglePassengerBlock(isPassengerDocumentsOpened, psgKey)">
                              <span class="material-symbols-outlined fs-20" [@rotate]="!isPassengerDocumentsOpened[psgKey]">
                                expand_less
                              </span>
                            </div>
                          </div>
                          <div class="card nested-card" [@collapse]="!isPassengerDocumentsOpened[psgKey]">
                            <div class="card-body">
                              <div formGroupName="documents">
                                <div *ngFor="let control of form.get(psgKey + '.documents')['controls']; let idx = index; first as first" [formGroupName]="idx">
                                  <div class="form-row" *ngIf="!documentSelectionState[psgKey + idx]">
                                    <div *ngIf="control.get('documentType') as identityDocumentType"
                                         class="form-group-sm col-lg-3 col-md-5 col-sm-11">
                                      <ng-select2
                                        [validate]="identityDocumentType.dirty || validateTriggered"
                                        formControlName="documentType"
                                        class="ng-select2-form-control select2-control"
                                        [placeholder]="'Document Type'"
                                        (click)="helpers.setFocusToSelect2SearchField()"
                                        (valueChanged)="documentSelectionState[psgKey + idx] = !documentSelectionState[psgKey + idx]"
                                      >
                                        <option *ngFor="let type of (documentTypeOptions | pairs)" [value]="type[0]">{{ type[1] }}
                                      </ng-select2>
                                    </div>
                                    <div class="ml-1 h-40 d-flex align-items-center" *ngIf="!requireDocumentId">
                                      <i class="material-symbols-outlined remove-icon fs-20"
                                         title="Remove"
                                         (click)="removeDocument(psgKey, idx)">
                                        delete
                                      </i>
                                    </div>
                                  </div>
                                  <ng-container *ngIf="documentSelectionState[psgKey + idx]">
                                    <div class="form-row mb-3" [class.mt-4]="!first">
                                      <div class="d-flex align-items-center gap-20 col-12">
                                        <div class="documents">
                                          {{documentTypeOptions[control.get('documentType').value]}}
                                        </div>
                                        <i *ngIf="!requireDocumentId || !first"
                                           class="material-symbols-outlined remove-icon fs-20"
                                           title="Remove"
                                           (click)="removeDocument(psgKey, idx)">
                                          delete
                                        </i>
                                      </div>
                                    </div>
                                    <div class="form-row">
                                      <div *ngIf="control.get('documentID') as identityDocumentID"
                                           class="form-group-sm col-lg-3 col-md-5">
                                        <label ngbTooltip="Iberia's Fiscal ID need this format: RUC/12345789/COMPANY" container="body">
                                          <span class="text-primary"><b>*</b></span> Document ID
                                        </label>
                                        <input [validate]="identityDocumentID.dirty || validateTriggered"
                                               type="text"
                                               formControlName="documentID"
                                               autocomplete="false"
                                               class="form-control"
                                               placeholder="XXXXXXX"/>
                                      </div>
                                      <div class="form-group-sm col-lg-3 col-md-5"
                                           *ngIf="control.get('expirationDate') as expirationDate">
                                        <label class="datepicker-label">
                                          Expiration date
                                        </label>
                                        <div class="datepicker-container">
                                          <input
                                            appDateInput
                                            [validate]="expirationDate.dirty || validateTriggered"
                                            autocomplete="false"
                                            [placement]="'bottom'"
                                            ngbDatepicker
                                            #t="ngbDatepicker"
                                            (click)="t.close()"
                                            (ngModelChange)="onExpirationDateChanged($event, control)"
                                            class="form-control"
                                            placeholder="DD-MM-YY"
                                            formControlName="expirationDate"
                                            [minDate]="minMaxDocumentExpirationDate.minDate"
                                          />
                                          <button (click)="t.toggle()">
                                            <i class="material-icons" style='color: white'>
                                              event
                                            </i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-row">
                                      <div *ngIf="control.get('fiscalName') as fiscalName"
                                           class="form-group-sm col-lg-3 col-md-5">
                                        <label>Fiscal Name</label>
                                        <input
                                          [validate]="fiscalName.dirty || validateTriggered"
                                          type="text"
                                          formControlName="fiscalName"
                                          placeholder="XXXXXXX"
                                          autocomplete="false"
                                          class="form-control">
                                      </div>
                                    </div>
                                    <div class="form-row">
                                      <div
                                        *ngIf="control.get('issuingCountryCode') as issuingCountryCode"
                                        class="form-group-sm col-lg-3 col-md-5">
                                        <label>Issuing Country Code</label>
                                        <ng-select2
                                          [validate]="issuingCountryCode.dirty || validateTriggered"
                                          formControlName="issuingCountryCode"
                                          class="ng-select2-form-control"
                                          [placeholder]="'Select Issuing Country Code...'"
                                          (click)="helpers.setFocusToSelect2SearchField()"
                                        >
                                          <option *ngFor="let c of (countries | pairs)" [value]="c[0]">{{ c[1] }}
                                        </ng-select2>
                                      </div>
                                      <div
                                        *ngIf="control.get('citizenshipCountryCode') as citizenshipCountryCode"
                                        class="form-group-sm col-lg-3 col-md-5">
                                        <label>Citizenship Country Code</label>
                                        <ng-select2
                                          [validate]="citizenshipCountryCode.dirty || validateTriggered"
                                          formControlName="citizenshipCountryCode"
                                          class="ng-select2-form-control"
                                          [placeholder]="'Select Citizenship Country Code...'"
                                          (click)="helpers.setFocusToSelect2SearchField()"
                                        >
                                          <option *ngFor="let c of (countries | pairs)" [value]="c[0]">{{ c[1] }}
                                        </ng-select2>
                                      </div>
                                      <div
                                        *ngIf="control.get('residenceCountryCode') as residenceCountryCode"
                                        class="form-group-sm col-lg-3 col-md-5">
                                        <label>Residence Country Code</label>
                                        <ng-select2
                                          [validate]="residenceCountryCode.dirty || validateTriggered"
                                          formControlName="residenceCountryCode"
                                          class="ng-select2-form-control"
                                          [placeholder]="'Select Residence Country Code...'"
                                          (click)="helpers.setFocusToSelect2SearchField()"
                                        >
                                          <option *ngFor="let c of (countries | pairs)" [value]="c[0]">{{ c[1] }}
                                        </ng-select2>
                                      </div>
                                    </div>
                                  </ng-container>
                                </div>
                                <div>
                                  <button class="btn btn-tonal-primary"
                                          title="Add Document"
                                          (click)="addDocument(psgKey)">
                                    <i class='fa fa-plus w-10 fs-10'></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <ng-container *ngIf="showDiscounts">
                            <ng-container *ngIf="offer.discounts.residentCode">
                              <div class="ml-3 mb-2 d-flex align-items-center justify-content-between">
                                <h4 class="h4-label">Residence Discount Iberia</h4>
                                <div class="expand-icon-wrapper" (click)="togglePassengerBlock(isPassengerResidenceDiscountIberiaOpened, psgKey)">
                                  <span class="material-symbols-outlined fs-20" [@rotate]="!isPassengerResidenceDiscountIberiaOpened[psgKey]">
                                    expand_less
                                  </span>
                                </div>
                              </div>
                              <div class="card nested-card" [@collapse]="!isPassengerResidenceDiscountIberiaOpened[psgKey]">
                                <div class="card-body">
                                  <div class="form-row" formGroupName="residenceDiscountIberia">
                                    <div class="col-lg-3 col-md-5">
                                      <label><span class="text-primary"><b>*</b></span> Identity Document Number</label>
                                      <input type="text"
                                             class="form-control"
                                             formControlName="documentIdResident"
                                             placeholder="Identity Document Number"
                                             autocomplete="false"
                                             [validate]="form.get(psgKey + '.residenceDiscountIberia.documentIdResident').dirty || validateTriggered">
                                    </div>
                                    <div class="col-lg-3 col-md-5">
                                      <label><span class="text-primary"><b>*</b></span> Code of Location</label>
                                      <ng-select2
                                        formControlName="documentTypeResident"
                                        class="ng-select2-form-control"
                                        [validate]="form.get(psgKey + '.residenceDiscountIberia.documentTypeResident').dirty || validateTriggered"
                                        [placeholder]="'Select code ...'"
                                        (click)="helpers.setFocusToSelect2SearchField()"
                                      >
                                        <option *ngFor="let discount of (residentDiscounts | pairs)" [value]="discount[0]">{{ discount[0] }} {{ discount[1] }}
                                        </option>
                                      </ng-select2>
                                    </div>

                                    <div class="col-md-2">
                                      <label>Resident in:</label>
                                      <div class="btn-group dropdown d-block">
                                        <button class="btn btn-light">
                                          {{iberiaDiscounts[psgKey].residentCode.identityDocumentType !== '' ? iberiaDiscounts[psgKey].residentCode.identityDocumentType : 'None' }}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ng-container>

                            <ng-container *ngIf="offer.discounts.largeFamily">
                              <div class="ml-3 mb-2 d-flex align-items-center justify-content-between">
                                <h4 class="h4-label">Large Family Discount Iberia</h4>
                                <div class="expand-icon-wrapper" (click)="togglePassengerBlock(isPassengerLargeFamilyDiscountIberiaOpened, psgKey)">
                                  <span class="material-symbols-outlined fs-20" [@rotate]="!isPassengerLargeFamilyDiscountIberiaOpened[psgKey]">
                                    expand_less
                                  </span>
                                </div>
                              </div>
                              <div class="card nested-card" [@collapse]="!isPassengerLargeFamilyDiscountIberiaOpened[psgKey]">
                                <div class="card-body">
                                  <div class="form-row" formGroupName="largeFamilyDiscountIberia">
                                    <div class="col-lg-3 col-md-5">
                                      <label><span class="text-primary"><b>*</b></span> Identity Document Number</label>
                                      <input type="text"
                                             class="form-control"
                                             formControlName="documentIdFamily"
                                             placeholder="Identity Document Number"
                                             autocomplete="false"
                                             [validate]="form.get(psgKey + '.largeFamilyDiscountIberia.documentIdFamily').dirty || validateTriggered">
                                      <div class="invalid-feedback">
                                      </div>
                                    </div>
                                    <div class="col-lg-3 col-md-5">
                                      <label><span class="text-primary"><b>*</b></span> Code of Community</label>
                                      <input type="text"
                                             class="form-control"
                                             formControlName="documentTypeFamily"
                                             placeholder="Code of Community"
                                             autocomplete="false"
                                             [validate]="form.get(psgKey + '.largeFamilyDiscountIberia.documentTypeFamily').dirty || validateTriggered">
                                      <div class="invalid-feedback">
                                      </div>
                                    </div>

                                    <div class="col-md-2">
                                      <label>Large family:</label>
                                      <div class="btn-group dropdown d-block">
                                        <button class="btn btn-light">
                                          {{largeFamilyList[iberiaDiscounts[psgKey].largeFamily.identityDocumentType]}}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </ng-container>

                          <div class="form-row">
                            <div class="mx-auto" [class.form-group]="offer.allowedExtraFields?.otherServiceInformation || ssrSelectedCount[ssrPassengerID]">
                              <span class="text-primary"><b>*</b></span> Required fields
                            </div>
                          </div>

                          <div class="form-row" *ngIf="offer.specialServices && offer.specialServices.length && ssrSelectedCount[ssrPassengerID]">
                            <div class="form-group col-md-12">
                              <table class="table table-default special-services-table mb-0">
                                <thead>
                                <tr>
                                  <th>Description</th>
                                  <th>Comment</th>
                                </tr>
                                </thead>
                                <tbody>
                                <ng-container *ngFor="let service of offer.specialServicesWithoutIncluded">
                                  <ng-container *ngFor="let flight of offer.flights">
                                    <ng-container *ngFor="let fs of flight.segments">
                                      <tr *ngIf="ssrSelected[ssrPassengerID][service.code + '|' +  fs.segmentID]">
                                        <td class="descr">{{service.desc}}</td>
                                        <td>
                                          <span
                                            *ngIf="service.bookingInstructions.mandatoryText !== 'not_allowed'"
                                          > {{ ssrSelectedText[ssrPassengerID][service.code + '|' + fs.segmentID] ? ssrSelectedText[ssrPassengerID][service.code + '|' + fs.segmentID] : '(not set)'}}</span>
                                          <span *ngIf="service.bookingInstructions.mandatoryText === 'not_allowed'">-</span>
                                        </td>
                                      </tr>
                                    </ng-container>
                                  </ng-container>
                                </ng-container>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </ngb-tab>
                  </ng-container>
                </ng-container>
              </ngb-tabset>

              <div class="osi" *ngIf="offer.allowedExtraFields?.otherServiceInformation">
                <div class="d-flex align-items-center justify-content-between my-3">
                  <div class="d-flex align-items-center gap-10">
                    <span class="material-symbols-outlined blue-color">
                      assignment
                    </span>
                    <h3 class="mb-0 h3-label">OSI</h3>
                  </div>
                  <div class="expand-icon-wrapper" (click)="isOsiOpened = !isOsiOpened">
                    <span class="material-symbols-outlined" [@rotate]="!isOsiOpened">
                      expand_less
                    </span>
                  </div>
                </div>
                <div class="card nested-card" [@collapse]="!isOsiOpened">
                  <div class="card-body">
                    <ng-container formGroupName="osi">
                      <ng-container *ngFor="let control of form.get('osi')['controls']; let osiIdx = index; first as first" [formGroupName]="osiIdx">
                        <div class="d-flex align-items-center gap-15 mb-3">
                          <div class="fs-16">
                            OSI {{osiIdx + 1}}
                          </div>
                          <i class="material-symbols-outlined remove-icon fs-18"
                             title="Remove"
                             (click)="removeOSI(osiIdx)">
                            delete
                          </i>
                        </div>
                        <div class="form-row">
                          <div class="form-group-sm col-md-2">
                            <label>OSI Code</label>
                            <input
                              type="text"
                              formControlName="osiCode"
                              autocomplete="false"
                              placeholder="Enter OSI Code"
                              class="form-control">
                          </div>

                          <div class="form-group-sm col-md-2">
                            <label>Action Code</label>
                            <input
                              type="text"
                              formControlName="actionCode"
                              autocomplete="false"
                              placeholder="Enter Action Code"
                              class="form-control">
                          </div>

                          <div class="form-group-sm col-md-8">
                            <label>Text</label>
                            <input
                              type="text"
                              formControlName="text"
                              autocomplete="false"
                              placeholder="Free text..."
                              class="form-control">
                          </div>
                        </div>

                        <div class="form-row">
                          <div class="col">
                            <label>Apply to:</label>
                            <div class="form-row">
                              <div class="custom-col form-group-sm">
                                <div class="form-control">
                                  <label class="checkbox custom-checkbox-1 mb-0">
                                    <input type="checkbox"
                                           [checked]="applyOsiByPassengerTypeDisabled[osiIdx]"
                                           (change)="onPassengerRefChange(control, $event.target.checked, osiIdx, -1)"/>
                                    <div class="checkbox__checkmark"></div>
                                    <div class="checkbox__body fs-15">
                                      All
                                    </div>
                                  </label>
                                </div>
                              </div>
                              <div class="vr mx-2 form-group-sm"></div>
                              <ng-container *ngFor="let travelerType of (travelers | pairs); let travTypeIdx = index">
                                <ng-container *ngFor="let travIdx of seatMapService.createRange(travelerType[1]);">
                                  <div class="custom-col form-group-sm">
                                    <div class="form-control" [class.disabled]="applyOsiByPassengerTypeDisabled[osiIdx]">
                                      <label class="checkbox custom-checkbox-1 mb-0">
                                        <input type="checkbox"
                                               [checked]="selectedPassengersPerOsi[osiIdx] && selectedPassengersPerOsi[osiIdx][travTypeIdx] && selectedPassengersPerOsi[osiIdx][travTypeIdx][travIdx] && !applyOsiByPassengerTypeDisabled[osiIdx]"
                                               (change)="onPassengerRefChange(control, $event.target.checked, osiIdx, travIdx, travTypeIdx, travelerType[0])"/>
                                        <div class="checkbox__checkmark"></div>
                                        <div class="checkbox__body fs-15">
                                          {{travelerType[0].toUpperCase() + ' ' +  (travIdx + 1)}}
                                        </div>
                                      </label>
                                    </div>
                                  </div>
                                </ng-container>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                    <div class="form-row">
                      <div class="col">
                        <button class="btn btn-tonal-primary"
                                title="Add OSI"
                                (click)="addOSI()">
                          <i class='fa fa-plus w-10 fs-10'></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="bookingSteps.RESERVATION">
              <app-offer-price-view
                [offer]="offer"
                [fareRules]="fareRules"
                [travelers]="travelers"
                [isShowDisclosureTable]="true"
                [useNewDesign]="true"
              ></app-offer-price-view>

              <div class="row pb-5">
                <div class="col-lg-12">
                  <div class="d-flex align-items-center justify-content-between mb-4">
                    <div class="d-flex align-items-center gap-10">
                      <span class="material-symbols-outlined blue-color">
                        person
                      </span>
                      <h3 class="mb-0 h3-label">Passengers</h3>
                    </div>
                    <div class="expand-icon-wrapper" (click)="isPassengersOpened = !isPassengersOpened">
                      <span class="material-symbols-outlined" [@rotate]="!isPassengersOpened">
                        expand_less
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12" [@collapse]="!isPassengersOpened">
                  <div class="ml-5" [class.mb-2]="!last" *ngFor="let p of passengersInfo; last as last">
                    <span class="fs-16">
                      <span *ngIf="p.passengerType" class="gray-700-color">({{p.passengerType}})</span>
                      {{ p.data.title }} {{ p.data.name }} {{ p.data.surname }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="row pb-5" *ngIf="selectedServices?.length > 0 || !!showSeats || (passengersInfo | specialServicesExist: includedSpecialServices)">
                <div class="col-lg-12">
                  <div class="d-flex align-items-center justify-content-between mb-4">
                    <div class="d-flex align-items-center gap-10">
                      <span class="material-symbols-outlined blue-color">
                        shopping_cart
                      </span>
                      <h3 class="mb-0 h3-label">Seats & Services</h3>
                    </div>
                    <div class="expand-icon-wrapper" (click)="isAncillariesOpened = !isAncillariesOpened">
                      <span class="material-symbols-outlined" [@rotate]="!isAncillariesOpened">
                        expand_less
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col" [@collapse]="!isAncillariesOpened">
                  <div class="table-responsive">
                    <table class="table table-default mb-0">
                      <thead>
                      <tr>
                        <th>Passenger</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th width="120px">Applies to</th>
                        <th>Price</th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      <ng-container *ngIf="servicePerSegment.entireTrip?.length > 0">
                        <tr *ngFor="let srv of servicePerSegment.entireTrip; let idx = index">
                          <ng-container [ngTemplateOutlet]="serviceTableRowTemplate" [ngTemplateOutletContext]="{srv: srv, appliesTo: 'Entire Trip'}"></ng-container>
                        </tr>
                      </ng-container>
                      <ng-container *ngIf="(servicePerSegment.perLeg | keyvalue)?.length > 0">
                        <ng-container *ngFor="let segmentServices of (servicePerSegment.perLeg | keyvalue) | sortFlightsByRoutes: offer.flights">
                          <ng-container *ngIf="segmentServices?.key">
                            <tr *ngFor="let srv of segmentServices.value; let idx = index">
                              <ng-container [ngTemplateOutlet]="serviceTableRowTemplate"
                                            [ngTemplateOutletContext]="{srv: srv, appliesTo: segmentServices.key?.replace('-', ' → ')}">
                              </ng-container>
                            </tr>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="(servicePerSegment.perSegment | keyvalue)?.length > 0">
                        <ng-container *ngFor="let segmentServices of (servicePerSegment.perSegment | keyvalue) | sortFlightsByRoutes: segments: false">
                          <ng-container *ngIf="segmentServices?.key">
                            <tr *ngFor="let srv of segmentServices.value; let idx = index">
                              <ng-container [ngTemplateOutlet]="serviceTableRowTemplate"
                                            [ngTemplateOutletContext]="{srv: srv, appliesTo: segmentServices.key?.replace('-', ' → ')}">
                              </ng-container>
                            </tr>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="!!showSeats">
                        <ng-container *ngFor="let segmentSeats of (seatsPerSegment | pairs)">
                          <ng-container *ngIf="segmentSeats[1]?.name">
                            <tr *ngFor="let seat of segmentSeats[1].seats">
                              <td class="text-nowrap">{{ passengerNameByRef(seat.travelerReference) }}</td>
                              <td [class.text-left]="selectedServices?.length">Seat</td>
                              <td [class.text-left]="selectedServices?.length">{{seat.location.column + seat.location.row}}</td>
                              <td>{{segmentSeats[1]?.name?.replace('-', ' → ')}}</td>
                              <td>{{seat?.price?.consumer.total | number :'1.2-2'}} {{seat?.price?.consumer.currency}}</td>
                              <td>
                                <div class="d-flex justify-content-end">
                                  <i title="Remove"
                                     class="material-symbols-outlined remove-icon"
                                     (click)="$event.preventDefault(); removeSeat(segmentSeats[0], seat)">
                                    delete
                                  </i>
                                </div>
                              </td>
                            </tr>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngFor="let p of passengersInfo; let i = index">
                        <ng-container *ngIf="p.specialServices && p.specialServices.length > 0">
                          <tr *ngFor="let ssr of p.specialServices">
                            <td class="text-nowrap">{{p.data?.name}} {{p.data?.surname}}</td>
                            <td [class.text-left]="selectedServices?.length">{{ssr.code}}</td>
                            <td class="text-left">{{ ssrCode2DescriptionMapping[ssr.code] }}</td>
                            <td>
                              <div *ngFor="let seg of ssr.segmentIDs">
                                {{ segment2FlightRouteMapping[seg]?.replace('-', ' → ') }}
                              </div>
                            </td>
                            <td>-</td>
                            <td>
                              <div class="d-flex justify-content-end">
                                <i title="Remove"
                                   class="material-symbols-outlined remove-icon"
                                   (click)="$event.preventDefault(); removeSsr(ssr, p.passengerID)">
                                  delete
                                </i>
                              </div>
                            </td>
                          </tr>
                        </ng-container>
                        <ng-container *ngIf="includedSpecialServices && includedSpecialServices[p.passengerType]?.length">
                          <tr *ngFor="let ssr of includedSpecialServices[p.passengerType]">
                            <td class="text-nowrap">{{p.data?.name}} {{p.data?.surname}}</td>
                            <td class="text-left">{{ssr.code}}</td>
                            <td class="text-left">{{ ssr.desc }}</td>
                            <td>
                              <div *ngFor="let seg of ssr.segmentIDs">
                                {{ segment2FlightRouteMapping[seg]?.replace('-', ' → ') }}
                              </div>
                            </td>
                            <td>-</td>
                            <td></td>
                          </tr>
                        </ng-container>
                      </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12" *ngIf="totalPrice">
                  <h3 class="total-price mt-0">Total Price: {{ totalPrice | number :'1.2-2'}} {{ offer.price.consumer.currency }}</h3>
                  <h4 *ngIf="surchargesInfo">Card Surcharges: {{ surchargesInfo }}</h4>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div
                    *ngIf="orderCreateSuccess && !bookingHasError"
                    class="alert-success p-3 rounded">
                    {{ orderCreateSuccess }}
                  </div>
                  <div
                    *ngIf="orderCreateError && bookingHasError"
                    class="alert-danger p-3 rounded">
                    {{ orderCreateError }}
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="card-footer" [ngSwitch]="selectedStep">
            <div class="row">
              <div class="col">
                <div class="tab-wrapper">
                  <div class="tab">
                    <div class="d-flex justify-content-center gap-20">
                      <div class="fw-500">
                        <ng-container [ngTemplateOutlet]="offerExpiration"></ng-container>
                      </div>
                      <div class="text-allowed fw-500" *ngIf="paymentSettings.none">On-hold allowed</div>
                      <div class="text-disallowed fw-500" *ngIf="!paymentSettings.none">On-hold NOT allowed</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="!bookingProcess.isProcess; else bookingProcessTemplate">
              <ng-container *ngSwitchCase="bookingSteps.RESERVATION">
                <div class="col-4 d-flex flex-wrap gap-12 align-items-center">
                  <app-popover-wrapper
                    popoverContent="Select seats are not available for this airline."
                    [disablePopover]="seatAvailabilityEnabled"
                    additionalClasses="d-inline-block"
                  >
                    <button [disabled]="!seatAvailabilityEnabled"
                            (click)="loadSeatAvailability(seatsModal);"
                            class="btn btn-outline-primary">
                      Select Seats
                    </button>
                  </app-popover-wrapper>
                  <app-popover-wrapper
                    popoverContent="Select Services are not available for this airline."
                    [disablePopover]="serviceListEnabled"
                    additionalClasses="d-inline-block"
                  >
                    <button [disabled]="!serviceListEnabled"
                            (click)="open(serviceListModal, 'xl')"
                            class="btn btn-outline-primary">
                      Select Services
                    </button>
                  </app-popover-wrapper>
                  <app-popover-wrapper
                    popoverContent="SSRs are not available for this airline."
                    [disablePopover]="offer.specialServicesWithoutIncluded?.length"
                    additionalClasses="d-inline-block"
                  >
                    <button [disabled]="!offer.specialServicesWithoutIncluded?.length"
                            class="btn btn-outline-primary"
                            (click)="openServices(specialServices)">
                      SSRs
                    </button>
                  </app-popover-wrapper>
                </div>
              </ng-container>
              <ng-container *ngIf="selectedStep !== bookingSteps.RESERVATION">
                <div class="col-4 d-flex align-items-center">
                  <app-export-to-pnr [order]="offer"></app-export-to-pnr>
                </div>
              </ng-container>
              <div class="col">
                <div id="booking-stepper" class="bs-stepper" *ngIf="offer">
                  <div class="bs-stepper-header">
                    <div class="step"
                         [class.active]="selectedStep == bookingSteps.OFFER"
                         [class.completed]="isOfferStepCompleted"
                         (click)="stepSelect(bookingSteps.OFFER)"
                    >
                      <button class="step-trigger">
                        <span class="bs-stepper-circle">
                          <ng-container *ngIf="!isOfferStepCompleted; else stepCompleted">
                            <span>1</span>
                          </ng-container>
                        </span>
                        <span class="bs-stepper-label" *ngIf="selectedStep == bookingSteps.OFFER">Offer</span>
                      </button>
                    </div>
                    <div class="line"></div>
                    <div class="step"
                         [class.active]="selectedStep == bookingSteps.PAX"
                         [class.disabled]="numberOfCompletedSteps < bookingSteps.PAX"
                         [class.completed]="isPaxStepCompleted"
                         (click)="stepSelect(bookingSteps.PAX)"
                    >
                      <button class="step-trigger">
                        <span class="bs-stepper-circle">
                          <ng-container *ngIf="!isPaxStepCompleted; else stepCompleted">
                            <span>2</span>
                          </ng-container>
                        </span>
                        <span class="bs-stepper-label" *ngIf="selectedStep == bookingSteps.PAX">PAX</span>
                      </button>
                    </div>
                    <ng-container *ngIf="hasRemarkTemplates">
                      <div class="line"></div>
                      <div class="step"
                           [class.active]="selectedStep == bookingSteps.REMARKS"
                           [class.disabled]="numberOfCompletedSteps < bookingSteps.REMARKS"
                           [class.completed]="isRemarksStepCompleted"
                           (click)="stepSelect(bookingSteps.REMARKS)"
                      >
                        <button class="step-trigger">
                          <span class="bs-stepper-circle">
                            <ng-container *ngIf="!isRemarksStepCompleted; else stepCompleted">
                              <span>3</span>
                            </ng-container>
                          </span>
                          <span class="bs-stepper-label" *ngIf="selectedStep == bookingSteps.REMARKS">Remarks</span>
                        </button>
                      </div>
                    </ng-container>
                    <div class="line"></div>
                    <div class="step"
                         [class.active]="selectedStep == bookingSteps.RESERVATION"
                         [class.disabled]="isReservationStepDisabled"
                         (click)="stepSelect(bookingSteps.RESERVATION)"
                    >
                      <button class="step-trigger">
                        <span class="bs-stepper-circle">
                          <span>{{hasRemarkTemplates ? '4' : '3'}}</span>
                        </span>
                        <span class="bs-stepper-label" *ngIf="selectedStep == bookingSteps.RESERVATION">Reserv.</span>
                      </button>
                    </div>
                  </div>
                  <ng-template #stepCompleted>
                    <i class="fas fa-check"></i>
                  </ng-template>
                </div>
              </div>
              <div class="col-4 d-flex flex-wrap gap-12 align-items-center justify-content-end">
                <ng-container *ngSwitchCase="bookingSteps.OFFER">
                  <button (click)="stepSelect(bookingSteps.PAX)" class="btn btn-primary">
                    Next
                  </button>
                </ng-container>
                <ng-container *ngSwitchCase="bookingSteps.PAX">
                  <button (click)="stepSelect(bookingSteps.OFFER)" class="btn btn-secondary">
                    Back
                  </button>
                  <button (click)="stepSelect(hasRemarkTemplates ? bookingSteps.REMARKS : bookingSteps.RESERVATION); passengersInfo = getPassengersInfo();"
                          class="btn btn-primary"
                          [ngbPopover]="form.invalid && errors && (errors | json) != '{}' ? paxIncorrectData : ''"
                          popoverClass="pax-data-popover-wp"
                          [autoClose]="'outside'"
                          placement="top"
                          #popOver="ngbPopover">
                    Next
                  </button>
                </ng-container>
                <ng-container *ngSwitchCase="bookingSteps.REMARKS">
                  <button (click)="stepSelect(bookingSteps.PAX);"
                          class="btn btn-secondary">
                    Back
                  </button>
                  <button (click)="stepSelect(bookingSteps.RESERVATION);"
                          class="btn btn-primary"
                          [ngbPopover]="'Remarks must be filled!'"
                          [autoClose]="'outside'"
                          placement="top">
                    {{ isAgencyRemarksTemplateSelected || isCorporateRemarksTemplateSelected ? 'Save & Continue' : 'Next'}}
                  </button>
                </ng-container>
                <ng-container *ngSwitchCase="bookingSteps.RESERVATION">
                  <button (click)="stepSelect(hasRemarkTemplates ? bookingSteps.REMARKS : bookingSteps.PAX)"
                          class="btn btn-secondary">
                    Back
                  </button>
                  <app-popover-wrapper
                    [popoverContent]="bookOnHoldPopover"
                    [disablePopover]="!isBookOnHoldDisabled"
                    additionalClasses="d-inline-block"
                  >
                    <button class="btn btn-tonal-primary"
                            [disabled]="isBookOnHoldDisabled"
                            (click)="makePayment(true)">
                      Book On Hold
                    </button>
                    <ng-template #bookOnHoldPopover>
                      {{!paymentSettings?.none ? 'Airline does not allow booking on hold.' : 'Booking On-hold with ancillaries is not allowed.'}}
                    </ng-template>
                  </app-popover-wrapper>
                  <app-popover-wrapper
                    [popoverContent]="bookAndPayPopover"
                    [disablePopover]="!isBookAndPayDisabled"
                    additionalClasses="d-inline-block"
                  >
                    <button class="btn btn-primary"
                            (click)="selectPayment()"
                            [disabled]="isBookAndPayDisabled">
                      Book & Pay
                    </button>
                    <ng-template #bookAndPayPopover>
                      Action is not available because of one of these reasons:
                      <ul class="mb-0">
                        <li>Your user role does not allow it</li>
                        <li>The Airline does not allow it</li>
                        <li>Remarks template has to be filled before ticketing order</li>
                      </ul>
                    </ng-template>
                  </app-popover-wrapper>
                </ng-container>
              </div>
            </div>
            <ng-template #bookingProcessTemplate>
              <app-show-processing [processInfo]="bookingProcess.processTitle"></app-show-processing>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #specialServices let-c="close" let-d="dismiss">
  <div class="modal-header align-items-center">
    <h3 class="card-header-title">Select Special Services</h3>
    <i
      *ngIf="!ssrErrorMessage"
      data-dismiss="modal"
      (click)="d('closed')"
      class="material-icons float-right close-icon">
      close
    </i>
  </div>
  <div class="modal-body">
    <div class="pl-2">
      <ngb-tabset #ssrTabs="ngbTabset" [destroyOnHide]="false" [activeId]="ssrPassengerID" (tabChange)="passengerSelected($event)">
        <ng-container *ngFor="let p of passengersInfo | excludePassengersPipe; let i = index">
          <ngb-tab id="{{ p.passengerID }}"
                   title="{{'(' + p.passengerType + ') ' + p.data.name + ' ' + p.data.surname }}">
            <ng-template ngbTabContent>
              <div class="pl-2">
                <ngb-tabset>
                  <ng-container *ngFor="let flight of offer.flights">
                    <ng-container *ngFor="let fs of flight.segments">
                      <ngb-tab
                        title="{{ fs.originDestination.departure.airportCode + ' → ' + fs.originDestination.arrival.airportCode }}">
                        <ng-template ngbTabContent>
                          <div class="table-responsive">
                            <h5 class="service-title">Additional services: </h5>
                            <table class="table table-default special-services-table mb-0">
                              <thead>
                              <tr>
                                <th>Description</th>
                                <th>Comment</th>
                                <th width="100px"></th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr *ngFor="let service of offer.specialServicesWithoutIncluded">
                                <td class="descr">{{service.desc}}</td>
                                <td>
                                  <textarea
                                    *ngIf="ssrSelected[ssrPassengerID][service.code + '|' +  fs.segmentID] && service.bookingInstructions.mandatoryText !== 'not_allowed'"
                                    [(ngModel)]="ssrSelectedText[ssrPassengerID][service.code + '|' + fs.segmentID]"
                                    [placeholder]="service.bookingInstructions.mandatoryText === 'mandatory' ? 'Comment required' : 'Comment optional'"
                                    (ngModelChange)="changeServiceCommentText(service, fs.segmentID)"
                                    [ngClass]="{'is-invalid' : !!ssrSelectedValidation[ssrPassengerID] && !!ssrSelectedValidation[ssrPassengerID][service.code + '|' +  fs.segmentID] }"
                                    class="form-control service-textarea" rows="3"
                                  ></textarea>
                                  <div *ngIf="!!ssrSelectedValidation[ssrPassengerID] && !!ssrSelectedValidation[ssrPassengerID][service.code + '|' +  fs.segmentID]"
                                       class="invalid-feedback">Comment required
                                  </div>
                                  <ng-container *ngIf="!ssrSelected[ssrPassengerID][service.code + '|' +  fs.segmentID] || service.bookingInstructions.mandatoryText === 'not_allowed'">
                                    -
                                  </ng-container>
                                </td>
                                <td>
                                 <span
                                   (click)="selectService(service, fs.segmentID, p.passengerID)"
                                   [class.btn-outline-primary]="!ssrSelected[ssrPassengerID][service.code + '|' + fs.segmentID]"
                                   [class.btn-tonal-primary]="ssrSelected[ssrPassengerID][service.code + '|' + fs.segmentID]"
                                   class="btn btn-sm mw-74">{{ssrSelected[ssrPassengerID][service.code + '|' + fs.segmentID] ? 'Selected' : 'Select' }}
                                 </span>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </ng-template>
                      </ngb-tab>
                    </ng-container>
                  </ng-container>
                </ngb-tabset>
              </div>
            </ng-template>
          </ngb-tab>
        </ng-container>
      </ngb-tabset>
    </div>
  </div>
  <div class="modal-footer">
    <div *ngIf="ssrErrorMessage"
         class="alert-warning rounded ssr-error-message mr-3"
    >{{ ssrErrorMessage }}</div>
    <div>
      <button
        type="button"
        class="btn btn-secondary"
        (click)="d('closed')">Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        (click)="saveServices(c)">Save
      </button>
    </div>
  </div>
</ng-template>

<ng-template #serviceListModal let-c="close" let-d="dismiss">
  <app-service-list-pre-modal
    [owner]="service.owner"
    [shoppingResponseID]="offer.shoppingResponseID"
    [flights]="offer.flights"
    [passengersData]="passengersInfo"
    [passengersList]="passengers"
    [discounts]="offer.discounts"
    (emitDismiss)="d('closed')"
    (emitClose)="c('ok')"
    (emitServicesSelected)="onServicesSelected($event)"
  ></app-service-list-pre-modal>
</ng-template>

<ng-template #seatsModal let-c="close" let-d="dismiss">
  <div class="modal-card card seats">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="card-header-title">Select Seats</h3>
        </div>
        <div class="col-auto">
          <i
            data-dismiss="modal"
            (click)="d('closed'); clearPopupNotifications()"
            class="material-icons float-right close-icon">
            close
          </i>
        </div>
      </div>
    </div>

    <div class="card-body">
      <div class="loader-wrapper" *ngIf="seatMapService.showSeatAvailabilityLoader">
        <app-loader></app-loader>
      </div>

      <div *ngIf="seatAvailabilityResponseWarning.length && !seatMapService.showSeatAvailabilityLoader">
        <div *ngIf="seatAvailabilityResponseWarning.length" class="alert-warning p-3 mb-3 rounded">
          <ul>
            <li *ngFor="let item of seatAvailabilityResponseWarning"
                [innerHTML]="item | htmlToStringParser | replace: '\n':'<br\/>'"
            ></li>
          </ul>
        </div>
      </div>
      <div *ngIf="!seatMapService.showSeatAvailabilityLoader">
        <error-alert-block
          [error]="seatMapService.seatAvailabilityError"
        ></error-alert-block>
      </div>

      <app-seat-map
        *ngIf="(seatMapService.seats | json) != '{}'"
        [passengers]="seatPassengers | sortPassengers"
        [segments]="seatsSegments"
        [seatAvailability]="seatAvailability"
        (seatSelect)="onSeatSelect($event)"
      ></app-seat-map>
    </div>
    <div class="card-footer text-right">
      <button
        type="button"
        class="btn btn-secondary mr-3"
        (click)="d('closed'); clearPopupNotifications()"
      >Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        (click)="showSelectedSeats(); c('ok')"
        [disabled]="isSelectSeatsButtonDisable"
      >Save
      </button>
    </div>
  </div>
</ng-template>

<ng-template #paymentFormModal let-c="close" let-d="dismiss">
  <div class="modal-card card payment">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="card-header-title">Order Issue</h3>
        </div>
        <div class="col-auto">
          <button type="button" class="close" data-dismiss="modal" (click)="d('closed')">×</button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <ng-container *ngIf="paymentStep === 0">
        <app-payment-form
          *ngIf="paymentService.form"
          [totalPrice]="totalPrice"
          [allowedPaymentMethods]="paymentSettings"
          [cardSurchargesMap]="offer.cardSurchargesMap"
          [validateTriggered]="paymentValidateTriggered"
          [currency]="offer.price.consumer.currency"
        ></app-payment-form>
      </ng-container>
      <ng-container *ngIf="paymentStep === 1">
        <app-payment-info
          [order]="offer"
          [shoppingResponseID]="offer.shoppingResponseID"
          [services]="selectedServices"
          [passengers]="offer.passengers"
          [type]="'pay_offer_on_ordercreate'">
        </app-payment-info>
      </ng-container>
    </div>
    <div class="card-footer">
      <div class="row">
        <div class="col d-flex flex-wrap gap-12 align-items-center justify-content-end">
          <button (click)="d('closed'); paymentStep = 0" class="btn btn-secondary" *ngIf="paymentStep === 0">Cancel</button>
          <button (click)="paymentStep = 0" class="btn btn-secondary" *ngIf="paymentStep > 0">Back</button>
          <button class="btn btn-primary"
                  [disabled]="isNonePaymentMethod"
                  (click)="proceedPayment(c)">
            Proceed
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #serviceTableRowTemplate let-srv="srv" let-appliesTo="appliesTo">
  <td class="text-nowrap">{{ passengerNameByRef(srv.travelerReferences) }}</td>
  <td class="text-left">{{ srv.name }}</td>
  <td class="text-left">
    <div class="d-flex align-items-center justify-content-between" *ngIf="srv.descriptions?.length">
      <div>
        <ng-container *ngFor="let description of srv.descriptions; let i = index">
          <div *ngIf="(srv.isExpanded || i == 0) && description !== srv.name">
            {{description}} {{srv.descriptions.length > 1 && !srv.isExpanded ? '...' : ''}}
          </div>
        </ng-container>
      </div>
      <button class="btn btn-secondary align-self-baseline py-1"
              *ngIf="srv.descriptions?.length > 1"
              (click)="srv.isExpanded = !srv.isExpanded">
        <i class='fa fs-10' [ngClass]="!srv.isExpanded ? 'fa-plus' : 'fa-minus'"></i>
      </button>
    </div>
    <div *ngIf="!srv.descriptions?.length">
      -
    </div>
  </td>
  <td>{{appliesTo}}</td>
  <td class="text-nowrap">{{ srv?.price?.consumer.total | number :'1.2-2'}} {{srv?.price?.consumer.currency }}</td>
  <td>
    <div class="d-flex justify-content-end">
      <i title="Remove"
         class="material-symbols-outlined remove-icon"
         (click)="$event.preventDefault(); removeService(srv.serviceID)">
        delete
      </i>
    </div>
  </td>
</ng-template>

<ng-template #confirmStoreModal let-c="close" let-d="dismiss">
  <div class="modal-card card confirm-store">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="card-header-title">Confirm Store Travelers</h3>
        </div>
        <div class="col-auto">
          <button type="button" class="close" data-dismiss="modal" (click)="d('closed'); this.confirmCallback();">×
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <ngb-accordion activeIds="{{ getActiveIDsForChanges(changes) }}">
        <ng-container *ngFor="let trav of (changes | pairs); let idx = index">
          <ngb-panel
            id="{{ 'passenger' + '_' + idx }}"
          >
            <ng-template ngbPanelTitle>
              <h3>Changes
                for {{ form.get(trav[0] + '.data.name').value + ' ' + form.get(trav[0] + '.data.surname').value }} </h3>
            </ng-template>
            <ng-template ngbPanelContent>
              <ng-container
                *ngIf="trav[1].length"
              >
                <ng-container [formGroup]="form.get(trav[0])">
                  <div class="form-row" formGroupName="data">
                    <div class="col-md-6">
                      <div class="form-check mt-0">
                        <input class="form-check-input" id="{{ 'st_profile_' + trav[0] + '_' + idx }}"
                               type="checkbox" formControlName="storeProfile">
                        <label class="form-check-label"
                               for="{{ 'st_profile_' + trav[0] + '_' + idx }}">
                          Store Traveler Profile

                        </label>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <div [ngClass]="{'disabled': !form.get(trav[0] + '.data.storeProfile').value}">
                  <div *ngFor="let ch of trav[1]">
                    <div *ngIf="isObject(ch.new); else elseBlock">
                      <div class="field" *ngIf="ch.field === 'birthdate' || ch.field === 'expirationDate'; else elseBlock2">
                        <b>{{ labelFor(ch.field) }}</b>:
                        <span class="old">{{valueFor(ch.field, ch.old)}}</span>
                        <i class="material-icons icon">arrow_right_alt</i>
                        <span class="new">{{valueFor(ch.field, ch.new)}}</span>
                      </div>
                      <ng-template #elseBlock2>
                        <div class="field" *ngFor="let ob of (ch.new | pairsImpure);">
                          <div
                            *ngIf="ob[0] !== 'birthdate' &&
                            hasDiff(valueFor(ch.field + '.' + ob[0], ch.old[ob[0]]), valueFor(ch.field + '.' + ob[0], ch.new[ob[0]])) &&
                            labelFor(ch.field + '.' + ob[0])"
                          >
                            <b>{{ labelFor(ch.field + '.' + ob[0]) }}</b>:
                            <span class="old">{{valueFor(ch.field + '.' + ob[0], ch.old[ob[0]])}}</span>
                            <i class="material-icons icon">arrow_right_alt</i>
                            <span class="new">{{valueFor(ch.field + '.' + ob[0], ch.new[ob[0]])}}</span>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                    <ng-template #elseBlock>
                      <div class="field"
                           *ngIf="hasDiff(valueFor(ch.field , ch.old), valueFor(ch.field, ch.new)) && labelFor(ch.field)">
                        <b>{{ labelFor(ch.field) }}</b>:
                        <span class="old">{{valueFor(ch.field, ch.old)}}</span>
                        <i class="material-icons icon">arrow_right_alt</i>
                        <span class="new">{{valueFor(ch.field, ch.new)}}</span>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!trav[1].length">
                <div>No changes</div>
              </ng-container>

            </ng-template>
          </ngb-panel>
        </ng-container>
      </ngb-accordion>
    </div>
    <div class="card-footer">
      <div class="row pl-3 pr-3">
        <div class="col-6 mb-4 mt-3"></div>
        <div class="col-6 mt-3">
          <span *ngIf="!hasStoreProfileEnabled()" (click)="c('ok') ; this.confirmCallback();"
                class="btn btn-primary float-right">Go to order</span>
          <span *ngIf="hasStoreProfileEnabled()" (click)="confirmStoreTravelers() ? c('ok') : ''"
                class="btn btn-primary float-right">Store Selected Travelers</span>
          <span (click)="d('closed'); this.confirmCallback(); " class="btn btn-secondary float-right mr-3">Cancel</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #paxIncorrectData>
  <ng-container *ngFor="let error of errors | keyvalue; first as first">
    <h3 class="popover-header" [class.mt-3]="!first">Missing info in: {{error.key | getPassengerFromControlName}}</h3>
    <ng-container *ngIf="error.key.includes('passenger') && errors[error.key]">
      <ul>
        <ng-container *ngFor="let err of errors[error.key] | keyvalue | sortPassengerControlNames; first as firstNested">
          <li *ngIf="firstNested">
            {{err.key | camelCaseToTitleCase}}
          </li>
        </ng-container>
      </ul>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #offerExpiration>
  <app-offer-expiration [time]="offer.createdAt" [expiresAt]="offer.expiresAt" (emitOfferExpiration)="onOfferExpired($event)"></app-offer-expiration>
</ng-template>
