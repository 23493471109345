import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import { ConfigService} from "./config.service";
import { RemarkDataToUpdate, RemarkTextToUpdate } from '../../shared/interfaces/remarks';
import {environment} from '../../../environments/environment';
import {LocalStorageService} from './local-storage.service';
import {Dictionary} from '../types/dictionary';
import {from, Observable, of} from 'rxjs';
import {CommentsModel} from '../models/comments.model';
import {PostOrderDateChangeModel} from '../models/post-order-date-change.model';
import {PostDateChangeWithId, PostRepriceOrder} from '../models/post-date-change-with-id.model';
import {catchError, switchMap} from 'rxjs/operators';
import {NotificationService} from './notification.service';
import { HtmlToStringParserPipe } from "../pipes/html-to-string-parser";
import { SentryService } from './sentry.service';
import {HelpersService} from "./helpers.service";
import {ERROR_CODES} from "../constants";


declare let $crisp;

@Injectable()
export class NDCApiService {
  private baseUrl = environment.ndcApiEndpoint + 'v1.2/';
  owner = '';
  lastExecutedMethod = '';
  lastSessionID = '';
  lastRequestID = '';

  airshoppingDefaultPayload: {} = {
    metadata: {
      'country': 'DE',
      'currency': 'EUR',
      'locale': 'de_DE'
    },
    originDestinations: [
      {
        'departure': {
          'airportCode': '',
          'date': '',
          'time': '',
          'terminalName': ''
        },
        'arrival': {
          'airportCode': '',
          'date': '',
          'time': '',
          'terminalName': ''
        }
      }
    ],
    preferences: {
      // 'cabin': ['5'],
      // 'fare': 'FLE',
      // 'nonStop': false
      maxStops: [10]
    },
  };


  constructor(public http: HttpClient,
              protected _notificationSvc: NotificationService,
              public ls: LocalStorageService,
              private config: ConfigService,
              private sentryService: SentryService,
              private helpers: HelpersService
    ) {
  }


  // private get baseUrl(): string {
  //   return this.ls.settings && this.ls.settings.url ? this.ls.settings.url : '';
  // }

  updateOrderComment(data: CommentsModel): Observable<any> {
    return this.http.post(`${this.baseUrl}OrderComments`, data);
  }

  updateOrderRemark(data: RemarkDataToUpdate | RemarkTextToUpdate): Observable<any> {
    return this.http.post(`${this.baseUrl}OrderRemarks`, data);
  }

  sendOrderReshop(data: PostOrderDateChangeModel): Observable<any> {
    return this.http.post(`${this.baseUrl}OrderReshop`, data, {observe: 'response'})
      .pipe(switchMap(res => {
        this.promiseTrackSessionID(res);
        return of(res.body);
      }), catchError((e) => {
        // this.trackErrors(e);
        throw e;
      }));
  }

  sendOrderReshopReprice(data: PostDateChangeWithId | PostRepriceOrder): Observable<any> {
    return this.http.post(`${this.baseUrl}OrderReshopReprice`, data, {observe: 'response'})
      .pipe(switchMap(res => {
        this.promiseTrackSessionID(res);
        return of(res);
      }), catchError((e) => {
        // this.trackErrors(e);
        throw e;
      }));
  }

  changeOrderDate(data): Observable<any> {
    return this.http.post(`${this.baseUrl}OrderChange`, data, {observe: 'response'})
      .pipe(switchMap(res => {
        this.promiseTrackSessionID(res);
        return of(res);
      }), catchError((e) => {
        // this.trackErrors(e);
        throw e;
      }));
  }

  prepareAirShoppingPayload(originDestinations, travelers, passengers, cabinType, selectedFare, discounts, currency, corporateSenderID, corporateDiscountCodes, corporateLoyaltyProgramCodes, qualifier, taxExemptions, checkedBaggageIncluded) {
    let payload = JSON.parse(JSON.stringify(this.airshoppingDefaultPayload));
    payload.originDestinations = originDestinations;
    if (passengers.length) {
      payload.passengers = passengers;
    } else {
      payload.travelers = travelers;
    }
    if (corporateSenderID) {
      payload.corporateSenderID = corporateSenderID;
    }
    payload.preferences['cabin'] = cabinType;
    if (discounts) {
      payload.preferences['discounts'] = discounts;
    }
    if (selectedFare && selectedFare.length) {
      payload.preferences['fareList'] = selectedFare.map(item => {
        const fare: { code?: string, definition: string } = {
          definition: item.definition
        };
        if (item.code) {
          fare.code = item.code;
        }
        return fare;
      });
    }

    payload.preferences['checkedBaggageIncluded'] = checkedBaggageIncluded;

    if (this.ls.settings.stops !== null && this.ls.settings.stops !== undefined) {
      payload.preferences.maxStops = [this.ls.settings.stops];
    }

    if (corporateDiscountCodes) {
      payload.corporateDiscountCodes = corporateDiscountCodes;
    }

    if (corporateLoyaltyProgramCodes) {
      payload.corporateLoyaltyProgramCodes = corporateLoyaltyProgramCodes;
    }

    if (qualifier) {
      payload.qualifier = qualifier;
    }
    if (taxExemptions?.length) {
      payload.taxExemptions = taxExemptions;
    }

    payload.metadata['currency'] = currency;
    this.sentryService.setAdditionalData(this.lastSessionID, this.lastRequestID, payload);
    return JSON.stringify(payload);
  }

  // <editor-fold desc="sendAirShopping">
  sendAirShopping(originDestinations, travelers, passengers, cabinTypes, selectedFare, discounts, currency, abortSignal, corporateSenderID, corporateDiscountCodes, corporateLoyaltyProgramCodes, providers, qualifier, taxExemptions, checkedBaggageIncluded /*xhr: XMLHttpRequest, callback*/) {
    this.lastExecutedMethod = Dictionary.MethodAirShopping;

    let payload = this.prepareAirShoppingPayload(originDestinations, travelers, passengers, cabinTypes, selectedFare, discounts, currency, corporateSenderID, corporateDiscountCodes, corporateLoyaltyProgramCodes, qualifier, taxExemptions, checkedBaggageIncluded);

    let url = `${this.baseUrl}AirShopping`;

    const headers = new Headers();
    headers.set('Authorization', `Bearer ${this.ls.token}`);
    headers.set('AG-Providers', providers || '*');
    headers.set('AG-Connection', 'keep-alive');
    headers.set('AG-Request-Timeout', this.ls.settings.requestTimeout || 60);
    headers.set('AG-Avoid-Disclosures', this.ls.settings.avoidDisclosures || false);

    if (this.ls.settings.searchMode) {
      headers.set('AG-Search-Mode', this.ls.settings.searchMode);
    }

    if (this.ls.agency) {
      headers.set('AG-Agency', this.ls.agency);
    }

    if (this.ls.settings.enableTracing) {
      headers.set('AG-Trace', '1');
    }

    if (this.ls.settings.enableLimitOfOffers && this.ls.settings.maxOffersPerCabin) {
      headers.set('ag-max-offers-per-cabin', this.ls.settings.maxOffersPerCabin);
    }

    return fetch(url, {
      method: 'POST',
      body: payload,
      headers,
      mode: 'cors',
      cache: 'no-cache',
      signal: abortSignal,
    });
  }

  // </editor-fold>

  // <editor-fold desc="sendOfferPrice">
  sendOfferPrice(body) {
    this.lastExecutedMethod = Dictionary.MethodFlightPrice;

    let headers = this.initHeaders();

    if (this.ls.settings.enableTracing) {
      headers = headers.set('AG-Trace', '1');
    }
    let options: any = {
      body: body,
      headers: headers,
      observe: 'response',
    };

    return this.http.request<HttpResponse<any>>('POST', `${this.baseUrl}OfferPrice`, options).toPromise()
      .then((res: HttpResponse<any>) => this.promiseTrackSessionID(res))
      .then((res: HttpResponse<any>) => this.promiseTrackErrors(res, true))
      .then((res: HttpResponse<any>) => this.promiseSendOnlyBody(res));
  }

  // </editor-fold>

  // <editor-fold desc="sendSeatAvailability">
  sendSeatAvailability(body, isShowNotification = false) {
    this.lastExecutedMethod = Dictionary.MethodSeatAvailability;

    let headers = this.initHeaders();

    if (this.ls.settings.enableTracing) {
      headers = headers.set('AG-Trace', '1');
    }
    let options: any = {
      body: body,
      headers: headers,
      observe: 'response',
    };

    return this.http.request<HttpResponse<any>>('POST', `${this.baseUrl}SeatAvailability`, options).toPromise()
      .then((res: HttpResponse<any>) => this.promiseTrackSessionID(res))
      .then((res: HttpResponse<any>) => this.promiseTrackErrors(res, true))
      .then((res: HttpResponse<any>) => this.promiseSendOnlyBody(res))
      .catch((res: HttpResponse<any>) => this.catchTrackErrors(res, true, isShowNotification));
  }

  // </editor-fold>

  // <editor-fold desc="sendOrderCreate">
  sendOrderCreate(shoppingResponseID, passengers, payment = null, corporateID = '', loyaltyProgramAccount = '', metas = null, remarks = null, qualifiers = null, osi: any[]): Observable<any> {
    this.lastExecutedMethod = Dictionary.MethodOrderCreate;

    let headers = this.initHeaders();

    if (this.ls.settings.enableTracing) {
      headers = headers.set('AG-Trace', '1');
    }
    let body: any = {
      shoppingResponseID,
      passengers
    };

    if (corporateID) {
      body.corporateID = corporateID;
    }

    if (loyaltyProgramAccount) {
      body.loyaltyProgramAccount = loyaltyProgramAccount;
    }

    if (metas) {
      body.metas = metas;
    }

    if (payment) {
      body.payment = payment;
    }

    if (remarks) {
      body.remarks = remarks;
    }

    if (qualifiers) {
      body.qualifiers = qualifiers;
    }

    if (osi?.length) {
      body.metadata = {
        otherServiceInformation: osi
      };
    }

    let options: any = {
      body,
      headers: headers,
      observe: 'response',
    };

    return from(this.http.request<HttpResponse<any>>('POST', `${this.baseUrl}OrderCreate`, options).toPromise()
      .then((res: HttpResponse<any>) => this.promiseTrackSessionID(res))
      .then((res: HttpResponse<any>) => this.promiseTrackErrors(res))
      .then((res: HttpResponse<any>) => this.promiseSendOnlyBody(res))
      .catch((res: HttpResponse<any>) => this.catchTrackErrors(res)));
  }

  // </editor-fold>

  // <editor-fold desc="sendOrderRetrieve">
  sendOrderRetrieve(body, resync = false) {
    this.lastExecutedMethod = Dictionary.MethodOrderRetrieve;
    let headers = this.initHeaders();

    if (this.ls.settings.enableTracing) {
      headers = headers.set('AG-Trace', '1');
    }
    if (resync) {
      headers = headers.set('AG-OrderRetrieve-Mode', 'airline');
    }
    let options: any = {
      body: body,
      headers: headers,
      observe: 'response',
    };

    return this.http.request<HttpResponse<any>>('POST', `${this.baseUrl}OrderRetrieve` , options).toPromise()
      .then((res: HttpResponse<any>) => this.promiseTrackSessionID(res))
      .then((res: HttpResponse<any>) => this.promiseSendOnlyBody(res))
      .catch((res: HttpResponse<any>) => this.catchTrackErrors(res, true, true));
  }

  // </editor-fold>

  // <editor-fold desc="sendOrderCancel">
  sendOrderCancel(body, isShowNotification = true) {
    this.lastExecutedMethod = Dictionary.MethodOrderCancel;

    let headers = this.initHeaders();

    if (this.ls.settings.enableTracing) {
      headers = headers.set('AG-Trace', '1');
    }
    let options: any = {
      body: body,
      headers: headers,
      observe: 'response',
    };

    return this.http.request<HttpResponse<any>>('POST', `${this.baseUrl}OrderCancel` , options).toPromise()
      .then((res: HttpResponse<any>) => this.promiseTrackSessionID(res))
      .then((res: HttpResponse<any>) => this.promiseTrackErrors(res, true))
      .then((res: HttpResponse<any>) => this.promiseSendOnlyBody(res))
      .catch((res: HttpResponse<any>) => this.catchTrackErrors(res, true, isShowNotification));
  }

  // </editor-fold>

  // <editor-fold desc="sendOrderReshopRefund">
  sendOrderReshopRefund(body, isShowNotification = false) {
    this.lastExecutedMethod = Dictionary.MethodItinReshopRefund;

    let headers = this.initHeaders();

    if (this.ls.settings.enableTracing) {
      headers = headers.set('AG-Trace', '1');
    }
    let options: any = {
      body: body,
      headers: headers,
      observe: 'response',
    };

    return this.http.request<HttpResponse<any>>('POST', `${this.baseUrl}OrderReshopRefund` , options).toPromise()
      .then((res: HttpResponse<any>) => this.promiseTrackSessionID(res))
      .then((res: HttpResponse<any>) => this.promiseTrackErrors(res, true))
      .then((res: HttpResponse<any>) => this.promiseSendOnlyBody(res))
      .catch((res: HttpResponse<any>) => this.catchTrackErrors(res, true, isShowNotification));
  }

  // </editor-fold>

  // <editor-fold desc="sendServiceList">
  sendServiceList(body, isShowNotification = false) {
    this.lastExecutedMethod = Dictionary.MethodServiceList;

    let headers = this.initHeaders();

    if (this.ls.settings.enableTracing) {
      headers = headers.set('AG-Trace', '1');
    }
    let options: any = {
      body: body,
      headers: headers,
      observe: 'response',
    };

    return this.http.request<HttpResponse<any>>('POST', `${this.baseUrl}ServiceList` , options).toPromise()
      .then((res: HttpResponse<any>) => this.promiseTrackSessionID(res))
      .then((res: HttpResponse<any>) => this.promiseTrackErrors(res, true))
      .then((res: HttpResponse<any>) => this.promiseSendOnlyBody(res))
      .catch((res: HttpResponse<any>) => this.catchTrackErrors(res, true, isShowNotification));
  }

  // </editor-fold>

  // <editor-fold desc="sendOrderChange">
  sendOrderChange(body, isShowNotification = true) {
    this.lastExecutedMethod = Dictionary.MethodOrderChange;

    let headers = this.initHeaders();

    if (this.ls.settings.enableTracing) {
      headers = headers.set('AG-Trace', '1');
    }
    let options: any = {
      body: body,
      headers: headers,
      observe: 'response',
    };

    return this.http.request<HttpResponse<any>>('POST', `${this.baseUrl}OrderChange` , options).toPromise()
      .then((res: HttpResponse<any>) => this.promiseTrackSessionID(res))
      .then((res: HttpResponse<any>) => this.promiseTrackErrors(res, true))
      .then((res: HttpResponse<any>) => this.promiseSendOnlyBody(res))
      .catch((res: HttpResponse<any>) => this.catchTrackErrors(res, true, isShowNotification));
  }

  // </editor-fold>

  // <editor-fold desc="sendOrderList">
  sendOrderList(params: HttpParams) {
    let headers = this.initHeaders();

    if (this.ls.settings.enableTracing) {
      headers = headers.set('AG-Trace', '1');
    }
    let options: any = {
      headers,
      params,
      observe: 'response',
    };

    return this.http.request<HttpResponse<any>>('GET', `${this.baseUrl}OrderList` , options).toPromise()
      .then((res: HttpResponse<any>) => this.promiseTrackSessionID(res))
      .then((res: HttpResponse<any>) => this.promiseTrackErrors(res, true))
      .then((res: HttpResponse<any>) => this.promiseSendOnlyBody(res))
      .catch((res: HttpResponse<any>) => this.catchTrackErrors(res, true));
  }

  // </editor-fold>

  // <editor-fold desc="sendOrderList">
  sendOrderExport(body) {
    let headers = this.initHeaders();

    if (this.ls.settings.enableTracing) {
      headers = headers.set('AG-Trace', '1');
    }

    let options: any = {
      body,
      headers,
      observe: 'response',
    };

    return this.http.request<HttpResponse<any>>('POST', `${this.baseUrl}OrderExport` , options).toPromise()
      .then((res: HttpResponse<any>) => this.promiseTrackSessionID(res))
      .then((res: HttpResponse<any>) => this.promiseTrackErrors(res))
      .then((res: HttpResponse<any>) => this.promiseSendOnlyBody(res))
      .catch((res: HttpResponse<any>) => this.catchTrackErrors(res));
  }

  // </editor-fold>

  // <editor-fold desc="sendAirDocIssue">
  sendAirDocIssue(body: { id: any; payment: any }) {
    this.lastExecutedMethod = Dictionary.MethodAirDocIssue;

    let headers = this.initHeaders();

    if (this.ls.settings.enableTracing) {
      headers = headers.set('AG-Trace', '1');
    }
    let options: any = {
      body: body,
      headers: headers,
      observe: 'response',
    };

    return this.http.request<HttpResponse<any>>('POST', `${this.baseUrl}AirDocIssue` , options).toPromise()
      .then((res: HttpResponse<any>) => this.promiseTrackSessionID(res))
      .then((res: HttpResponse<any>) => this.promiseTrackErrors(res, true))
      .then((res: HttpResponse<any>) => this.promiseSendOnlyBody(res))
      .catch((res: HttpResponse<any>) => this.catchTrackErrors(res, true));
  }

  // </editor-fold>

  // <editor-fold desc="sendProviderList">
  sendProviderList() {
    let options: any = {
      observe: 'response',
    };

    return this.http.request<HttpResponse<any>>('GET', `${this.baseUrl}ProviderList` , options).toPromise()
      .then((res: HttpResponse<any>) => this.promiseTrackSessionID(res))
      .then((res: HttpResponse<any>) => this.promiseTrackErrors(res))
      .then((res: HttpResponse<any>) => this.promiseSendOnlyBody(res))
      .catch((res: HttpResponse<any>) => this.catchTrackErrors(res));
  }

  // </editor-fold>

  getOrderHistory(id: string) {
    let options: any = {
      observe: 'response',
    };

    return this.http.request<HttpResponse<any>>('GET', `${this.baseUrl}OrderHistory?id=${id}`, options).toPromise()
      .then((res: HttpResponse<any>) => this.promiseTrackSessionID(res))
      .then((res: HttpResponse<any>) => this.promiseSendOnlyBody(res))
      .catch((res: HttpResponse<any>) => this.catchTrackErrors(res, true, true));
  }

  getOrderFile(id: string, fileID: string) {
    let options: any = {
      observe: 'response',
      responseType: 'text',
    };

    return this.http.request<HttpResponse<any>>('GET', `${this.baseUrl}OrderFile?id=${id}&file_id=${fileID}`, options).toPromise()
      .then((res: HttpResponse<any>) => this.promiseTrackSessionID(res))
      .then((res: HttpResponse<any>) => this.promiseSendOnlyBody(res))
      .catch((res: HttpResponse<any>) => this.catchTrackErrors(res, true, true));
  }

  orderUpdate(body) {
    let headers = this.initHeaders();

    if (this.ls.settings.enableTracing) {
      headers = headers.set('AG-Trace', '1');
    }
    let options: any = {
      body: body,
      headers: headers,
      observe: 'response',
    };

    return this.http.request<HttpResponse<any>>('POST', `${this.baseUrl}OrderUpdate`, options).toPromise()
      .then((res: HttpResponse<any>) => this.promiseTrackSessionID(res))
      .then((res: HttpResponse<any>) => this.promiseTrackErrors(res))
      .then((res: HttpResponse<any>) => this.promiseSendOnlyBody(res))
      .catch((res: HttpResponse<any>) => this.catchTrackErrors(res, false, true));
  }

  sendOrderPDF(body: { id: string }) {
    let options: any = {
      body,
      observe: 'response',
    };

    return this.http.request<HttpResponse<any>>('POST', `${this.baseUrl}OrderPDF`, options).toPromise()
      .then((res: HttpResponse<any>) => this.promiseTrackSessionID(res))
      .then((res: HttpResponse<any>) => this.promiseSendOnlyBody(res))
      .catch((res: HttpResponse<any>) => this.catchTrackErrors(res, true, true));
  }

  orderNotificationSeenToggle(body, isShowNotification = true) {
    let headers = this.initHeaders();

    if (this.ls.settings.enableTracing) {
      headers = headers.set('AG-Trace', '1');
    }
    let options: any = {
      body,
      headers,
      observe: 'response',
    };

    return this.http.request<HttpResponse<any>>('POST', `${this.baseUrl}OrderNotificationSeenToggle`, options).toPromise()
      .then((res: HttpResponse<any>) => this.promiseTrackSessionID(res))
      .then((res: HttpResponse<any>) => this.promiseTrackErrors(res))
      .then((res: HttpResponse<any>) => this.promiseSendOnlyBody(res))
      .catch((res: HttpResponse<any>) => this.catchTrackErrors(res, false, isShowNotification));
  }

  createTask(body, isShowNotification = true) {
    let options: any = {
      body,
      observe: 'response',
    };

    return this.http.request<HttpResponse<any>>('POST', `${this.baseUrl}CreateTask`, options).toPromise()
      .then((res: HttpResponse<any>) => this.promiseTrackSessionID(res))
      .then((res: HttpResponse<any>) => this.promiseTrackErrors(res))
      .then((res: HttpResponse<any>) => this.promiseSendOnlyBody(res))
      .catch((res: HttpResponse<any>) => this.catchTrackErrors(res, false, isShowNotification));
  }

  sendPaymentInfo(body, isShowNotification = false) {
    let options: any = {
      body,
      observe: 'response',
    };

    return this.http.request<HttpResponse<any>>('POST', `${this.baseUrl}PaymentInfo`, options).toPromise()
      .then((res: HttpResponse<any>) => this.promiseTrackSessionID(res))
      .then((res: HttpResponse<any>) => this.promiseTrackErrors(res, false))
      .then((res: HttpResponse<any>) => this.promiseSendOnlyBody(res))
      .catch((res: HttpResponse<any>) => this.catchTrackErrors(res, false, isShowNotification));
  }

  promiseTrackSessionID(res: HttpResponse<any>): any {
    this.lastSessionID = res.headers.get('Ag-Session-Id');
    this.lastRequestID = res.headers.get('Ag-Request-Id');
    this.updateCrispSessionData();

    return res;
  }

  trackErrors(res: HttpResponse<any>, isPermanent = false, isShowNotification = true): any {
    let htmlToStringParserPipe = new HtmlToStringParserPipe();
    if (res.status >= 400) {
      // @ts-ignore
      let body = res.error;
      let errorType = -1;
      let msg = '';
      let title = '';
      let isProviderError = false;
      let errorCode = `[${res.status} - ${body?.code || 'unknown'}] `;

      switch (typeof body) {
        case 'object':
          if (body.detail) {
            msg = body.detail;
          } else {
            msg = body.error;
          }
          if (body.group === ERROR_CODES.PROVIDER_ERROR) {
            isProviderError = true;
          }
          title = body.group;
          msg = msg.replace(/;/ig, '<br>');
          msg = htmlToStringParserPipe.transform(msg);
          break;
        default:
          msg = body || 'Unexpected error';
          break;
      }
      msg = errorCode + msg;

      if (isShowNotification) {
        if (isProviderError) {
          this._notificationSvc.providerError(errorType, msg, res.status, isPermanent ? 0 : 8000, title);
        } else {
          this._notificationSvc.error(errorType, msg, res.status, isPermanent ? 0 : 8000, title);
        }
      }
    }
  }

  promiseTrackErrors(res: HttpResponse<any>, isPermanent = false): any {
    this.trackErrors(res, isPermanent);
    return res;
  }

  catchTrackErrors(res: HttpResponse<any>, isPermanent = false, isShowNotification = true): any {
    this.trackErrors(res, isPermanent, isShowNotification);
    throw res;
  }

  promiseSendOnlyBody(res: HttpResponse<any>): any {
    return res.body;
  }

  // <editor-fold desc="IsLastMethods">
  isLastMethodAirShopping() {
    return this.lastExecutedMethod === Dictionary.MethodAirShopping;
  }

  isLastMethodFlightPrice() {
    return this.lastExecutedMethod === Dictionary.MethodFlightPrice;
  }

  isLastMethodSeatAvailability() {
    return this.lastExecutedMethod === Dictionary.MethodSeatAvailability;
  }

  isLastMethodOrderCreate() {
    return this.lastExecutedMethod === Dictionary.MethodOrderCreate;
  }

  isLastMethodOrderRetrieve() {
    return this.lastExecutedMethod === Dictionary.MethodOrderRetrieve;
  }

  isLastMethodOrderCancel() {
    return this.lastExecutedMethod === Dictionary.MethodOrderCancel;
  }

  isLastMethodServiceList() {
    return this.lastExecutedMethod === Dictionary.MethodServiceList;
  }

  isLastMethodOrderChange() {
    return this.lastExecutedMethod === Dictionary.MethodOrderChange;
  }

  isLastMethodAirDocIssue() {
    return this.lastExecutedMethod === Dictionary.MethodAirDocIssue;
  }

  isLastMethodTicketRefund() {
    return this.lastExecutedMethod === Dictionary.MethodTicketRefund;
  }

  isLastMethodTicketVoid() {
    return this.lastExecutedMethod === Dictionary.MethodTicketVoid;
  }

// </editor-fold>

  private initHeaders(): HttpHeaders {
    return new HttpHeaders();
  }

  updateCrispSessionData() {
    if (typeof $crisp !== 'undefined' && environment.name === 'prod') {
      const isSessionOngoing = $crisp && typeof $crisp.is === 'function' ? $crisp.is("session:ongoing") : false;
      if (!isSessionOngoing) {
        const tracerLink = 'https://tracer.airgateway' + environment.domain + '/search?q=' + this.lastSessionID;
        $crisp.push(["set", "session:data", [[
          ["last_session_id", this.lastSessionID],
          ["last_request_id", this.lastRequestID],
        ]]]);

        if (this.lastSessionID) {
          $crisp.push(["set", "session:data", [[
            ["last_tracer_link", tracerLink]
          ]]]);
        }

        if (window.location.href.includes('orders/') && this.helpers.lastOrderPnr) {
          $crisp.push(["set", "session:data", [[
            ["last_order_pnr", this.helpers.lastOrderPnr]
          ]]]);
        }
      }
    }
  }

}
